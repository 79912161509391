import { Component, OnInit } from "@angular/core";
import { UrlService } from "src/app/services/url.service";

@Component({
  selector: "app-maintenance",
  templateUrl: "./maintenance.component.html",
})
export class MaintenanceComponent implements OnInit {
  constructor(public urlService: UrlService) {}

  ngOnInit(): void {}
}
