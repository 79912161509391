import { Component, Output, EventEmitter, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-registration-number",
  templateUrl: "./registration-number.component.html",
})
export class RegistrationNumberComponent {
  @Input() id: string;
  @Input() placeholder: string;
  @Input() parentForm: FormGroup;
  @Input() formControlKey: string;

  @Output() confirmed = new EventEmitter();
  constructor() {}

  clear(): void {
    this.parentForm.get(this.formControlKey)?.setValue("");
  }
}
