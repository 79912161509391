import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "imageResolver",
})
export class ImageResolverPipe implements PipeTransform {
  transform(assetPath: string): string {
    if (typeof FEATURE_APP_BASE_URL !== "undefined" && FEATURE_APP_BASE_URL) {
      return FEATURE_APP_BASE_URL + assetPath;
    }
    return assetPath;
  }
}
