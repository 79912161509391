import { Component, OnInit } from "@angular/core";
import { FormService } from "src/app/services/form.service";

@Component({
  selector: "app-service-agreement-package-info",
  templateUrl: "./service-agreement-package-info.component.html",
})
export class ServiceAgreementPackageInfoComponent implements OnInit {
  constructor(public formService: FormService) {}

  ngOnInit(): void {}
}
