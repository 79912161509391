import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CarBrandPipe } from "src/app/pipes/car-brand.pipe";
import { FormService } from "src/app/services/form.service";
import { IconService } from "src/app/services/icon.service";
import { PagingService } from "src/app/services/paging.service";
import { TrackingService } from "src/app/services/tracking.service";
import {
  Client,
  SwaggerException,
  GetTechUpdateStatusRequest,
  GetTechUpdateStatusResponse,
  VehicleSearchRequest,
  VehicleSearchResponse,
} from "src/domain/client";
import {
  ComponentStateEnum,
  PageBaseComponent,
} from "../../base/page-base/page-base.component";
import { Subscription } from "rxjs";
import { FeatureAppService } from "src/app/services/feature-app.service";
import { BusinessProposalService } from "src/app/services/business-proposal.service";
import { UrlService } from "src/app/services/url.service";

@Component({
  selector: "app-about-vehicle",
  templateUrl: "./about-vehicle.component.html",
})
export class AboutVehicleComponent
  extends PageBaseComponent
  implements OnInit, OnDestroy
{
  carTextValueSubscription: Subscription = null;
  shouldRebrand = false;
  brandMatchFailed = false;
  carValidated = false;
  businessProposalStatus: ComponentStateEnum;
  businessProposalStatusText: string;

  constructor(
    public formService: FormService,
    private route: ActivatedRoute,
    public pagingService: PagingService,
    private router: Router,
    public dialog: MatDialog,
    private trackingService: TrackingService,
    public iconService: IconService,
    private carBrandPipe: CarBrandPipe,
    private featureAppService: FeatureAppService,
    private businessProposalService: BusinessProposalService,
    public urlService: UrlService
  ) {
    super();
    this.formService.aboutVehicleForm.markAsPristine();
    this.formService.previousFormValue =
      this.formService.aboutVehicleForm.value;
    if (!!this.formService.aboutVehicleForm.valid) return;
    if (!!this.featureAppService.featureAppRegNr()) {
      this.formService.aboutVehicleForm.patchValue({
        carText: this.featureAppService.featureAppRegNr(),
      });
    }
    this.subscription.add(
      route.queryParamMap.subscribe({
        next: (result: any) => {
          this.shouldRebrand = !!result.params.rebrand;
          this.formService.searchedByRegno =
            !result.params.chassi && !result.params.vin;
          const carText = !!this.carText.value
            ? this.carText.value
            : !!this.formService.searchedByRegno
            ? !!result.params.regnr
              ? result.params.regnr.replace(" ", "")
              : !!result.params.rn
              ? result.params.rn.replace(" ", "")
              : ""
            : !!result.params.chassi
            ? result.params.chassi.replace(" ", "")
            : !!result.params.vin
            ? result.params.vin.replace(" ", "")
            : "";

          const mileage = !!this.milage.value
            ? this.milage.value
            : !!result.params.milage
            ? parseInt(result.params.milage, 10)
              ? parseInt(result.params.milage, 10)
              : null
            : !!result.params.mileage
            ? parseInt(result.params.mileage, 10)
              ? parseInt(result.params.mileage, 10)
              : null
            : null;

          this.formService.aboutVehicleForm.patchValue({
            carText,
            milage: mileage,
          });
          // try providers
          this.formService.providerIds = !!result.params.providers
            ? decodeURIComponent(result.params.providers).split(",")
            : [];
          // dealers
          this.formService.providerIds = !!result.params.dealers
            ? decodeURIComponent(result.params.dealers).split(",")
            : this.formService.providerIds;
          // then try dl for backward compability with SBO
          this.formService.providerIds = !!result.params.dl
            ? decodeURIComponent(result.params.dl).split(",")
            : this.formService.providerIds;
          if (!!this.formService.providerIds.length)
            this.formService.providerIds = this.formService.providerIds;
        },
      })
    );
  }

  ngOnDestroy(): void {
    if (!this.milage.pristine)
      this.trackingService.trackMilageSelected(this.milage.value);
    this.cleanUp();
  }

  ngOnInit(): void {
    if (!!this.carInfoResponse && this.carText.valid && this.milage.value) {
      this.carValidated = true;
      return;
    }

    if (this.businessProposalService.isBusinessProposalActive) {
      this.businessProposalService.getStatus().subscribe({
        next: (status) => {
          this.businessProposalStatus = status;
          this.businessProposalStatusText =
            this.businessProposalService.getStatusReasonUntranslated();
          if (
            ![
              ComponentStateEnum.Loading,
              ComponentStateEnum.Initialized,
            ].includes(status)
          ) {
            this.initForm();
          }
        },
      });
    } else {
      this.initForm();
    }
  }

  onSearchConfirm(): void {
    if (this.state == this.ComponentStateEnum.Loading) return;
    this.state = this.ComponentStateEnum.Initialized;
    this.brandMatchFailed = false;
    this.carValidated = false;
    if (
      !!this.formService.searchedByRegno &&
      !this.carText.valid &&
      this.carText.value.replace(" ", "").length !== 6
    ) {
      return;
    }
    if (
      !this.formService.searchedByRegno &&
      this.carText.value.replace(" ", "").length !== 17
    )
      return;
    this.state = this.ComponentStateEnum.Loading;
    new Client()
      .apiV2VehicleSearch(
        new VehicleSearchRequest({
          registrationNumber: !!this.formService.searchedByRegno
            ? this.carText.value.replace(/\s/g, "")
            : undefined,
          chassiNumber: !this.formService.searchedByRegno
            ? this.carText.value
            : undefined,
        })
      )
      .then(
        (response) => {
          this.formService.carInfoResponse = response;
          if (!!this.shouldRebrand)
            this.pagingService.rebrand(this.carBrandPipe.transform(response));
          if (
            this.urlService.brand === this.carBrandPipe.transform(response) ||
            this.urlService.brand === "default"
          ) {
            this.carValidated = true;
          } else {
            this.brandMatchFailed = true;
          }
          this.formService.aboutVehicleForm.markAsTouched();
          this.trackingService.trackSelectCar(
            this.formService.searchedByRegno,
            response.modelVariantName,
            response.brandName,
            response.modelYear.toString(),
            "", // We don't have this right now
            response.hasServiceAgreement,
            response.isCompanyVehicle,
            response.originalVehicleSegment.toString(),
            response.fuelType
          );
          this.checkPassiveTechUpdate(response.chassiNumber);
          this.state = this.ComponentStateEnum.Initialized;
        },
        (reason: SwaggerException) => {
          if (reason.status === 404 || reason.status === 503) {
            this.router.navigate([this.urlService.brand + "/unavailable"]);
          }
          this.formService.carInfoResponse = null;
          this.state = this.ComponentStateEnum.ApiCallFailed;
        }
      );
  }

  checkPassiveTechUpdate(chassi: string): void {
    new Client()
      .apiV2VehicleGetTechUpdateStatus(
        new GetTechUpdateStatusRequest({
          chassiNumber: chassi,
        })
      )
      .then((response: GetTechUpdateStatusResponse) => {
        this.formService.techUpdateStatus = response;
      });
  }

  onTextTypeToggle(): void {
    this.formService.searchedByRegno = !this.formService.searchedByRegno;
    this.state = this.ComponentStateEnum.Initialized;
    this.onCarChange();
  }

  onCarChange(): void {
    this.formService.aboutVehicleForm.markAsDirty();
    this.formService.aboutVehicleForm.patchValue({
      carText: "",
    });
    this.formService.aboutVehicleForm.markAsUntouched();
    this.carText.setValidators([
      Validators.required,
      Validators.pattern(
        !!this.formService.searchedByRegno
          ? this.formService.regNrRegex
          : this.formService.chassiNrRegex
      ),
    ]);
    this.state = this.ComponentStateEnum.Initialized;
    this.brandMatchFailed = false;
    this.carValidated = false;
    if (!!this.formService.searchedByRegno) this.setSubscriptions();
  }

  initForm(): void {
    this.carText.setValidators([
      Validators.required,
      Validators.pattern(
        !!this.formService.searchedByRegno
          ? this.formService.regNrRegex
          : this.formService.chassiNrRegex
      ),
    ]);
    this.onSearchConfirm();
    this.setSubscriptions();
  }

  setSubscriptions(): void {
    this.carTextValueSubscription = this.carText.valueChanges.subscribe((_) => {
      if (this.carText.valid) return this.onSearchConfirm();
    });
  }

  get carText(): AbstractControl {
    return this.formService.aboutVehicleForm.get("carText");
  }
  get milage(): AbstractControl {
    return this.formService.aboutVehicleForm.get("milage");
  }
  get carInfoResponse(): VehicleSearchResponse {
    return this.formService.carInfoResponse;
  }
  get newBrandUrl(): string {
    return (
      window.location.origin +
      "/" +
      this.carBrandPipe.transform(this.carInfoResponse) +
      "/form/1/?" +
      "rn=" +
      this.carText.value +
      "&mileage=" +
      this.milage.value
    );
  }
}
