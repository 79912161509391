import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-text-box",
  templateUrl: "./text-box.component.html",
})
export class TextBoxComponent implements OnInit {
  @Input() type: "text" | "email" | "password" | "tel" | "number";
  @Input() id: string;
  @Input() parentForm: FormGroup<any>;
  @Input() formControlKey: string;
  @Input() placeholder?: string;
  @Input() maxLen?: number;
  @Input() clearable?: boolean;
  @Input() showBorder?: boolean;
  @Input() invalidText?: string;
  @Input() mask?: string;
  @Output() cleared: EventEmitter<void> = new EventEmitter();

  @Output() confirm$ = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  clear(): void {
    this.control.setValue("");
    if (this.cleared) {
      this.cleared.emit();
    }
  }

  confirm(): void {
    this.confirm$.emit();
  }

  get control(): AbstractControl {
    return this.parentForm.get(this.formControlKey);
  }

  validate(): void {
    if (!this.control.valid) {
      this.control.setErrors({ invalid: true });
    }
  }
}
