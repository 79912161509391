import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  PackageResponse,
  PackageTagEnum,
  PackageTypeEnum,
  PriceTypeEnum,
  SelectedPackagesRequest,
} from "src/domain/client";
import { FormService } from "../services/form.service";
import { PriceService } from "../services/price.service";
import { PricePipe } from "./sharedpipes/price.pipe";

@Pipe({
  name: "packagePrice",
  pure: false, // Change to true when available
})
export class PackagePricePipe implements PipeTransform {
  constructor(
    private priceService: PriceService,
    private translateService: TranslateService,
    private formService: FormService,
    private pricePipe: PricePipe
  ) {}

  transform(
    pkg: PackageResponse,
    summary = false,
    includeSubpackages = true,
    showSubCategoryPrice = false,
    isTotalPrice = false
  ): string {
    if (pkg == null) return "0 kr";
    const currentSelection = this.formService.allSelectedPackages;
    const currentSelectedPackage = currentSelection.find(
      (s) => (s.packageId?.toString() || s.packageExternalId) === pkg.id
    );
    const hasSelectedSubPackage =
      !!currentSelectedPackage &&
      pkg.subPackages?.some((sp) => {
        return currentSelectedPackage.subPackageIds.includes(sp.id);
      });
    const basePriceString =
      !summary || pkg.type == PackageTypeEnum.BaseService
        ? this.pricePipe.transform(
            Math.round(
              this.priceService.getPriceOfPackage(
                pkg,
                currentSelection,
                includeSubpackages
              )
            )
          )
        : Math.round(pkg.price?.discounted);
    const total = isTotalPrice
      ? this.translateService.instant("summary.pay.total") + ": "
      : "";
    if (
      pkg.type == PackageTypeEnum.OriginalService ||
      pkg.type == PackageTypeEnum.BaseService
    ) {
      if (pkg.tags.includes(PackageTagEnum.ServiceAgreement))
        return this.translateService.instant("service-included");
      if (pkg.price?.type == PriceTypeEnum.Offer)
        return this.translateService.instant("offer-price");
      if (pkg.price?.type == PriceTypeEnum.From)
        return total + "fr. " + basePriceString + " kr";
      if (pkg.price?.type == PriceTypeEnum.Free)
        return this.translateService.instant("free-price");
      if (pkg.price?.type == PriceTypeEnum.Fixed)
        return total + basePriceString + " kr";
      return this.pricePipe.transform(
        this.priceService.getPriceOfPackage(pkg, currentSelection) + " kr"
      );
    }
    if (pkg.isSubCategory && showSubCategoryPrice && includeSubpackages) {
      if (this.selectedSubPackagesIncludesOffer(pkg, currentSelectedPackage)) {
        return total + this.translateService.instant("offer-price");
      }
      return total + basePriceString + " kr";
    }
    if (pkg.type == PackageTypeEnum.TechUpdate)
      return this.translateService.instant("tech-update-price");
    if (
      pkg.price?.type == PriceTypeEnum.Free &&
      !(isTotalPrice && hasSelectedSubPackage)
    )
      return this.translateService.instant("free-price");
    if (
      pkg.price?.type == PriceTypeEnum.Deductibles &&
      !(isTotalPrice && hasSelectedSubPackage)
    )
      return this.translateService.instant("deductibles");
    if (pkg.price?.type == PriceTypeEnum.Deductibles)
      return total + "fr. " + basePriceString + " kr";
    if (pkg.price?.type == PriceTypeEnum.Offer)
      return this.translateService.instant("offer-price");

    if (pkg.price?.type == PriceTypeEnum.Fixed)
      return total + basePriceString + " kr";
    if (pkg.price?.type == PriceTypeEnum.From)
      return total + "fr. " + basePriceString + " kr";
    if (pkg.price?.type == PriceTypeEnum.Litre)
      return total + basePriceString + "kr/l";
    if (
      pkg.price?.type === PriceTypeEnum.Calculated &&
      pkg.price.price == 0 &&
      !!pkg.subPackages.length &&
      isTotalPrice
    ) {
      if (this.selectedSubPackagesIncludesOffer(pkg, currentSelectedPackage)) {
        return total + this.translateService.instant("offer-price");
      }
      return total + basePriceString + " kr";
    }
    if (pkg.price?.type == PriceTypeEnum.Calculated) {
      if (pkg.price.price == 0) return "";
      return this.pricePipe.transform(
        Math.round(this.priceService.getPriceOfPackage(pkg, currentSelection)) +
          " kr"
      );
    }
    return total + basePriceString + " kr";
  }

  private selectedSubPackagesIncludesOffer(
    pkg: PackageResponse,
    selection: SelectedPackagesRequest
  ): boolean {
    const values = pkg.subPackages
      .filter((sp) => !!selection?.subPackageIds?.includes(sp.id))
      .map((sp) => sp.price?.type);
    return values.length > 0 && values.every((t) => t === PriceTypeEnum.Offer);
  }
}
