import { Component, OnInit } from "@angular/core";
import { ApplicationMessageService } from "src/app/services/application-message.service";
import { IconService } from "src/app/services/icon.service";
import { ApplicationMessageTypeEnum } from "src/domain/client";

@Component({
  selector: "app-application-messages",
  templateUrl: "./application-messages.component.html",
})
export class ApplicationMessagesComponent implements OnInit {
  ApplicationMessageTypeEnum = ApplicationMessageTypeEnum;

  constructor(
    public applicationMessageService: ApplicationMessageService,
    public iconService: IconService,
  ) {}

  ngOnInit(): void {
    this.applicationMessageService.getApplicationMessages();
  }
}
