import { Component, Input, OnInit } from "@angular/core";
import { BrandEnum } from "src/app/classes/enums";

@Component({
  selector: "app-brand-card",
  templateUrl: "./brand-card.component.html",
})
export class BrandCardComponent implements OnInit {
  public BrandEnum: typeof BrandEnum = BrandEnum;
  @Input() brand: string;

  constructor() {}

  ngOnInit(): void {}
}
