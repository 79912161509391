import { Component, Input, OnInit } from "@angular/core";
import { FormService } from "src/app/services/form.service";
import { IconService } from "src/app/services/icon.service";
import {
  DealerResponse,
  DealerWorkshopSystemResponse,
  WorkshopSystemEnum,
} from "src/domain/client";

@Component({
  selector: "app-workshop-info",
  templateUrl: "./workshop-info.component.html",
})
export class WorkshopInfoComponent implements OnInit {
  @Input() workshop: DealerResponse;
  @Input() hideServices?: boolean;

  public dealerPropertyDescription: string;
  public showDealerPropertyDescription: boolean;

  constructor(
    public formService: FormService,
    public iconService: IconService,
  ) {}

  ngOnInit(): void {}

  getIconSelection(): WorkshopSystemEnum[] {
    if (
      this.formService.serviceTypeSelection.includes(
        WorkshopSystemEnum.TechUpdate,
      )
    )
      return Object.values(WorkshopSystemEnum)
        .filter((s) => s === WorkshopSystemEnum.TechUpdate)
        .concat(
          Object.values(WorkshopSystemEnum).filter(
            (s) => s !== WorkshopSystemEnum.TechUpdate,
          ),
        );
    return Object.values(WorkshopSystemEnum).filter(
      (v) => WorkshopSystemEnum[v] !== WorkshopSystemEnum.TechUpdate,
    );
  }

  hasDealerSystem(
    sys: WorkshopSystemEnum,
    dealerSys: DealerWorkshopSystemResponse[],
  ): boolean {
    if (sys === WorkshopSystemEnum.TechUpdate)
      return this.formService.serviceTypeSelection.includes(
        WorkshopSystemEnum.TechUpdate,
      );
    if (sys === WorkshopSystemEnum.Other) return true;
    return !!dealerSys.filter((s) => s.workshopSystem === sys).length;
  }

  handleDealerPropertyClicked(description: string): void {
    if (this.dealerPropertyDescription == description) {
      this.dealerPropertyDescription = null;
      this.showDealerPropertyDescription = false;
      return;
    }
    this.dealerPropertyDescription = description;
    this.showDealerPropertyDescription = true;
  }
}
