import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { BaseIconService } from "./icon.service.base";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class IconService extends BaseIconService {
  public registeredSvgIcons: string[];

  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    super();
    // If you change things here, please also update `./index.feature-app.ts` as well
    matIconRegistry
      .addSvgIcon(
        "wheel",
        domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/wheel.svg")
      )
      .addSvgIcon(
        "windscreen",
        domSanitizer.bypassSecurityTrustResourceUrl(
          "/assets/icons/windscreen.svg"
        )
      )
      .addSvgIcon(
        "mrf",
        domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/mrflogo.svg")
      )
      .addSvgIcon(
        "europcar",
        domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/europcar.svg")
      );
    this.registeredSvgIcons = Array.from(
      (matIconRegistry as any)._svgIconConfigs.keys()
    ).map((key: string) => key.replace(":", ""));
  }
}
