import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { expandAnimation } from "src/app/animations/expand";
import { FormService } from "src/app/services/form.service";
import { PriceService } from "src/app/services/price.service";
import {
  PackageResponse,
  PackageTagEnum,
  PackageTypeEnum,
  PriceTypeEnum,
  PropertyEnum,
} from "src/domain/client";
import { DialogTypeEnum } from "src/app/classes/enums";
import { DialogComponent } from "../dialog/dialog.component";
import { IconService } from "src/app/services/icon.service";
import { CarBrandPipe } from "src/app/pipes/car-brand.pipe";
import { PagingService } from "src/app/services/paging.service";
import { Subscription } from "rxjs";
import { UrlService } from "src/app/services/url.service";

@Component({
  selector: "app-package-container",
  templateUrl: "./package-container.component.html",
  animations: [expandAnimation],
})
export class PackageContainerComponent implements OnInit, OnDestroy {
  @Input() package: PackageResponse;
  @Input() parentFormControlKey: string;
  @Input() parentForm: FormGroup<any>;
  @Input() collapsed = false;
  @Input() type: "radio" | "checkbox" = "radio";

  public PropertyEnum: typeof PropertyEnum = PropertyEnum;
  public PackageTagEnum: typeof PackageTagEnum = PackageTagEnum;
  public DialogTypeEnum: typeof DialogTypeEnum = DialogTypeEnum;
  public PriceTypeEnum: typeof PriceTypeEnum = PriceTypeEnum;
  public PackageTypeEnum: typeof PackageTypeEnum = PackageTypeEnum;

  mainPackage: PackageResponse;
  isSubPackagesVisible = false;
  tags: string[] = [];

  public isParentChangedSubscription = new Subscription();

  constructor(
    public formService: FormService,
    public priceService: PriceService,
    public dialog: MatDialog,
    public translateService: TranslateService,
    public iconService: IconService,
    private carBrandPipe: CarBrandPipe,
    public pagingService: PagingService,
    private urlService: UrlService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  deferChange() {
    // Detatch the view from change detection
    this.changeDetectorRef.detach();
    setTimeout(() => {
      // Reattach the view ASAP
      // but after the current
      // frame/message in the event loop
      this.changeDetectorRef.reattach();
    }, 0);
  }

  ngOnInit(): void {
    this.toggleSubPackageValidity(false, true);
    if (this.type == "radio") {
      if (
        this.package.id == this.parentForm.parent.get("selectedContainer").value
      )
        this.toggleSubPackageValidity(true);
      this.isParentChangedSubscription.add(
        this.parentForm.parent.get("selectedContainer").valueChanges.subscribe({
          next: (val) => {
            if (this.package.id != val) {
              this.toggleSubPackageValidity(false);
            }
          },
        })
      );
    }
    this.tags = this.getTags(this.package);
  }

  ngOnDestroy(): void {
    this.isParentChangedSubscription.unsubscribe();
  }

  setMainPackage(): void {
    this.mainPackage = this.package.subPackages.find((p) =>
      p.tags.includes(PackageTagEnum.MainPackage)
    );
  }

  get fillsRequirements(): boolean {
    if (!this.isCheckedRadio) return false;
    return !Object.values(this.parentForm.value).filter((v) => !v).length;
  }

  parentClicked(event: MouseEvent): void {
    if (this.parentForm.parent.get("selectedContainer")?.disabled) return;

    const selected = this.parentForm.parent.get("selectedContainer");
    if (selected) selected.markAsDirty();
    const selectedsub = this.parentForm.get("subcategoryContainer");
    if (!!this.isCheckedRadio) {
      event?.preventDefault();
      /*
      Trying to deselect a clicked radio button in the
      onClick-handler results in no visual change defer
      the value change propagation until after the click handler
      */
      this.deferChange();
      if (!!selectedsub) {
        selectedsub.setValue(null);
      }
      this.isSubPackagesVisible = false;
      this.toggleSubPackageValidity(false);
      selected.setValue(null);

      return;
    }
    if (!selectedsub || this.type == "radio") {
      this.toggleSubPackageValidity(true);
      return;
    }
    if (!!this.isCheckedCheckbox) {
      selectedsub.disable({ emitEvent: false, onlySelf: true });
      selectedsub.setValue(null);
      this.toggleSubPackageValidity(false);
    } else {
      selectedsub.enable({ emitEvent: false, onlySelf: true });
      this.toggleSubPackageValidity(true);
    }
    this.collapsed = false;
  }

  subCategoryRadioClicked(
    event: MouseEvent,
    subpackage: PackageResponse
  ): void {
    const selected = this.parentForm.parent.get("selectedContainer");
    const selectedsub = this.parentForm.get("subcategoryContainer");
    const isSubCategoryChecked = this.isSubCategoryChecked(subpackage);

    if (selectedsub.disabled) return;

    selectedsub.markAsDirty();

    if (this.type === "radio") {
      if (isSubCategoryChecked) {
        event.preventDefault();
        /*
        Trying to deselect a clicked radio button in the
        onClick-handler results in no visual change defer
        the value change propagation until after the click handler
        */
        this.deferChange();
        selected.setValue(null);
        selectedsub.setValue(null);
        this.toggleSubPackageValidity(false);
        return;
      } else {
        selected.setValue(this.parentFormControlKey);
      }
    } else {
      if (isSubCategoryChecked) {
        event.preventDefault();
        /*
        Trying to deselect a clicked radio button in the
        onClick-handler results in no visual change defer
        the value change propagation until after the click handler
        */
        this.deferChange();
        this.parentForm?.get("selected").setValue(false);
        selectedsub.setValue(null);
        this.toggleSubPackageValidity(false);
        return;
      } else {
        this.parentForm?.get("selected").setValue(true);
      }
    }

    selectedsub.setValue(subpackage.id);
    this.toggleSubPackageValidity(true);
  }

  hasRemovableSubPackages(): boolean {
    return this.package?.subPackages?.some((sp) =>
      sp.tags.includes(PackageTagEnum.Removable)
    );
  }

  isSubCategoryChecked(subpackage: PackageResponse): boolean {
    return this.parentForm.get("subcategoryContainer")?.value === subpackage.id;
  }

  isOriginalService(p: PackageResponse): boolean {
    return p.type === PackageTypeEnum.OriginalService;
  }

  isBaseService(p: PackageResponse): boolean {
    return p.type === PackageTypeEnum.BaseService;
  }

  get isCheckedCheckbox(): boolean {
    return this.parentForm.get("selected")?.value;
  }

  get isCheckedRadio(): boolean {
    return (
      this.parentFormControlKey ===
      this.parentForm.parent.get("selectedContainer")?.value
    );
  }

  get isParentSelected(): boolean {
    return this.isCheckedCheckbox || this.isCheckedRadio;
  }

  openModal(type: DialogTypeEnum, title: string): void {
    document.getElementById("vbo-header").scrollIntoView(true);
    const _ref = this.dialog.open(DialogComponent, {
      data: {
        componentName: type,
        title,
        brand: this.carBrandPipe.transform(this.formService.carInfoResponse),
      },
      maxWidth: "600px",
      width: "90%",
    });
  }

  private toggleSubPackageValidity(valid: boolean, initial = false): void {
    if (valid && !this.formService.isStateDialogOpen) {
      this.isSubPackagesVisible = true;
    }
    const subCategoryForm = this.parentForm.get("subcategoryContainer");
    if (!subCategoryForm) return;
    if (!valid && !initial) subCategoryForm.setValue(null);
  }

  getTags(p: PackageResponse): string[] {
    if (
      this.isOriginalService(p) ||
      this.isBaseService(p) ||
      p.price?.discounted === p.price?.price
    )
      return [];
    const tags: string[] = [];
    const segment = this.formService.segment;
    if (
      segment !== 1 &&
      (p.type?.includes(PackageTypeEnum.ServiceAdditional) ||
        (p.type?.includes(PackageTypeEnum.DealerExtra) &&
          p.tags?.includes(PackageTagEnum.PPS)))
    ) {
      tags.push(this.getSegmentTag(segment));
    }
    if (p.tags?.includes(PackageTagEnum.Economy)) {
      tags.push(this.translateService.instant("package.economy"));
    }
    if (p.tags?.includes(PackageTagEnum.Campaign)) {
      tags.push(this.translateService.instant("package.campain"));
    }
    return tags;
  }

  private getSegmentTag(segment: number): string {
    const brandCode = this.formService.carInfoResponse?.brandCode;
    const segmentYear = segment === 2 ? " 4+" : segment === 3 ? " 8+" : "";
    if (!brandCode) {
      return this.urlService.brand + segmentYear;
    }
    let brandName = this.capitalizeFirstLetter(
      this.carBrandPipe.transform(this.formService.carInfoResponse)
    );
    if (brandName == "Vw") brandName = "Volkswagen";
    return brandName + segmentYear;
  }

  private capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
