import { environment } from "src/environments/environment";

export default class ClientBase {
  public getBaseUrl(data: string, baseUrl: string): string {
    if (typeof FEATURE_APP_BASE_URL !== "undefined" && FEATURE_APP_BASE_URL) {
      var baseUrl = FEATURE_APP_BASE_URL;
      return baseUrl + environment.apiUrl;
    }
    return location.protocol + "//" + location.host + environment.apiUrl;
  }
}
