import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-invalid-info-box",
  templateUrl: "./invalid-info-box.component.html",
})
export class InvalidInfoBoxComponent implements OnInit {
  @Input() text: string;

  constructor() {}

  ngOnInit(): void {}
}
