import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  AfterContentInit,
  OnDestroy,
} from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class SliderComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input() min: number;
  @Input() max: number;
  @Input() enabled: boolean;
  @Input() parentForm: FormGroup<any>;
  @Input() formControlKey: string;

  @Output() confirm$ = new EventEmitter<number>();

  @ViewChild("sliderInput") input: ElementRef<HTMLInputElement>;

  value = 0;

  valueSubscription: Subscription;

  onRawInput(event: InputEvent) {
    const { value: newValue } =
      event.currentTarget as (typeof event)["currentTarget"] & {
        readonly value?: unknown;
      };
    const numVal = Number(newValue);
    this.control.setValue(numVal);
  }

  onValueChange(event: number) {
    this.value = event;
    this.confirm$.emit(event);
    this.control.setValue(event);
  }

  isDragging = false;

  onDragStart(): void {
    this.input.nativeElement.addEventListener("input", this.onRawInput);
    this.isDragging = true;
  }

  onDragEnd(): void {
    this.input.nativeElement.removeEventListener("input", this.onRawInput);
    this.isDragging = false;
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return (
        Math.round(value / 1000) + "k" + (value >= 20000 ? "+" : "").toString()
      );
    }
    return value.toString();
  }

  constructor() {
    this.onRawInput = this.onRawInput.bind(this);
  }

  ngOnInit() {}

  ngAfterContentInit() {
    this.valueSubscription = this.control.valueChanges.subscribe((value) => {
      if (!this.isDragging) this.value = value;
    });
  }

  ngOnDestroy() {
    this.valueSubscription.unsubscribe();
  }

  get control(): AbstractControl<number> {
    return this.parentForm.get(this.formControlKey);
  }
}
