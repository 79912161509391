import { Component } from "@angular/core";
import {
  MINIMAL_CONSENT,
  FULL_CONSENT,
} from "src/app/services/consent.service";
import { PagingService } from "src/app/services/paging.service";
import { IConsentFormResult } from "src/app/interfaces/interfaces";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";

type ConsentForm = {
  [key in keyof IConsentFormResult]: AbstractControl<IConsentFormResult[key]>;
};

@Component({
  selector: "app-dialog-cookie",
  templateUrl: "./dialog-cookie.component.html",
})
export class DialogCookieComponent {
  MINIMAL = MINIMAL_CONSENT;
  FULL = FULL_CONSENT;

  constructor(public pagingService: PagingService) {}

  adjustCookies = false;

  cookieForm = new FormGroup<ConsentForm>({
    required: new FormControl({ value: true, disabled: true }),
    functional: new FormControl(true),
    performance: new FormControl(true),
    marketing: new FormControl(true),
  });
}
