export type availableBrand =
  | "audi"
  | "skoda"
  | "volkswagen"
  | "volkswagen transport"
  | "cupra"
  | "seat";
export type cardType = "brand-card" | "accessories" | "insurance";

export interface PromoCardProps {
  imageUrl: string;
  title: string;
  body: string;
  linkText: string;
  linkUrl: string;
  type: cardType;
}

export interface PromoCardData {
  brand: availableBrand;
  cards: PromoCardProps[];
}

export const promoCardData: PromoCardData[] = [
  {
    brand: "audi",
    cards: [
      {
        type: "brand-card",
        imageUrl: "/assets/images/brand-cards/audi-card.png",
        title: "Dela upp köpet på 12 månader",
        body: "Med AudiKort Visa kan du dela upp din service, verkstadskostnader och andra inköp hos din Audiåterförsäljare på 12 månader utan ränta och avgifter.<br/><br/>Du får dessutom poäng på alla inköp, oavsett vad du handlar. Du får 15 öre i rabatt per liter drivmedel du tankar på Preems bemannade stationer. AudiKort Visa har ingen årsavgift.",
        linkText: "Ansök här",
        linkUrl:
          "https://www.audi.se/se/web/sv/customer-area/service-tillbehor/audikort-visa.html",
      },
      {
        type: "accessories",
        imageUrl: "https://tbo2.vwgroup.se/api/api/File/ID.4GTXIC0496.jpg",
        title: "Tillbehör Online",
        body: "Med Audis genomtänkta och kvalitetstestade Originaltillbehör utnyttjar du potentialen i din Audi fullt ut.<br/>Audi Originaltillbehör är som gjorda för din bil. Bokstavligt talat.",
        linkText: "Klicka här för att köpa tillbehör till din bil",
        linkUrl: "https://auditillbehor.se/audi",
      },
      {
        type: "insurance",
        imageUrl: "https://tbo2.vwgroup.se/api/api/File/CDC0907.jpeg",
        title: "Audi Försäkring",
        body: "Audi Försäkring är skräddarsydd för din Audi. Har du inte vår försäkring och vill ta del av vårt erbjudande (gäller endast nya kunder)? Klicka nedan så kontaktar vi dig.<br/><br/><strong>Alltid med Audi Försäkring:</strong><br/><ul><li>Lägre självrisk - 3 000 kr lägre självrisk vid skada som täcks av vagnskadegarantin</li><li>Trygga reparationer - alltid Originaldelar på en auktoriserad märkesverkstad</li><li>En maskin- och elektronikförsäkring som räcker länge - 10 år eller upp till 15 000 mil</li></ul>",
        linkText: "Jag vill bli kontaktad - klicka här",
        linkUrl: "https://www.audibilforsakring.se/service_leads",
      },
    ],
  },
  {
    brand: "skoda",
    cards: [
      {
        type: "brand-card",
        imageUrl: "/assets/images/brand-cards/skoda-card.png",
        title: "Dela upp köpet på 12 månader",
        body: "Med Škoda Kortet Visa kan du dela upp din service, verkstadskostnader och andra inköp hos din Skodaåterförsäljare på 12 månader utan ränta och avgifter.<br/><br/>Du får dessutom poäng på alla inköp, oavsett vad du handlar. Du får 15 öre i rabatt per liter drivmedel du tankar på Preems bemannade stationer. Škoda Kortet Visa har ingen årsavgift.",
        linkText: "Ansök här",
        linkUrl: "https://www.skoda.se/aga-bil/skoda-kortet-visa-fordelar",
      },
      {
        type: "accessories",
        imageUrl: "https://tbo2.vwgroup.se/api/api/File/ID.4GTXIC0496.jpg",
        title: "Tillbehör Online",
        body: "Vi har ett brett sortiment av tillbehör speciellt framtagna för just din Škodamodell.",
        linkText: "Klicka här för att köpa tillbehör till din bil",
        linkUrl: "https://skodatillbehor.se/skoda",
      },
      {
        type: "insurance",
        imageUrl: "https://tbo2.vwgroup.se/api/api/File/CDC0907.jpeg",
        title: "Škoda Försäkring",
        body: "Škoda Försäkring är skräddarsydd för din Škoda. Har du inte vår försäkring och vill ta del av vårt erbjudande (gäller endast nya kunder)? Klicka nedan så kontaktar vi dig.<br/><br/><strong>Alltid med Škoda Försäkring:</strong><br/><ul><li>Lägre självrisk - 3 000 kr lägre självrisk vid skada som täcks av vagnskadegarantin</li><li>Trygga reparationer - alltid Originaldelar på en auktoriserad märkesverkstad</li><li>En maskin- och elektronikförsäkring som räcker länge - 10 år eller upp till 15 000 mil</li></ul>",
        linkText: "Jag vill bli kontaktad - klicka här",
        linkUrl: "https://www.skodabilforsakring.se/service_leads",
      },
    ],
  },
  {
    brand: "volkswagen",
    cards: [
      {
        type: "brand-card",
        imageUrl: "/assets/images/brand-cards/vw-card.png",
        title: "Dela upp köpet på 12 månader",
        body: "Med Volkswagenkortet Visa kan du dela upp din service, verkstadskostnader och andra inköp hos din Volkswagenåterförsäljare på 12 månader utan ränta och avgifter.<br/><br/>Du får dessutom poäng på alla inköp, oavsett vad du handlar. Du får 15 öre i rabatt per liter drivmedel du tankar på Preems bemannade stationer. Volkswagenkortet Visa har ingen årsavgift.",
        linkText: "Ansök här",
        linkUrl: "https://www.volkswagen.se/sv/aga-en-vw/volkswagenkortet.html",
      },
      {
        type: "accessories",
        imageUrl: "https://tbo2.vwgroup.se/api/api/File/ID.4GTXIC0496.jpg",
        title: "Tillbehör Online",
        body: "Ta en titt i vår Tillbehörskatalog. Du hittar alla originaltillbehör för just din bil och kan beställa direkt online.",
        linkText: "Klicka här för att köpa tillbehör till din bil",
        linkUrl: "https://vwtillbehor.se/vw",
      },
      {
        type: "insurance",
        imageUrl: "https://tbo2.vwgroup.se/api/api/File/CDC0907.jpeg",
        title: "Volkswagen Försäkring",
        body: "Volkswagen Försäkring är skräddarsydd för din Volkswagen. Har du inte vår försäkring och vill ta del av vårt erbjudande (gäller endast nya kunder)? Klicka nedan så kontaktar vi dig.<br/><br/><strong>Alltid med Volkswagen Försäkring:</strong><br/><ul><li>Lägre självrisk - 3 000 kr lägre självrisk vid skada som täcks av vagnskadegarantin</li><li>Trygga reparationer - alltid Originaldelar på en auktoriserad märkesverkstad</li><li>En maskin- och elektronikförsäkring som räcker länge - 10 år eller upp till 15 000 mil</li></ul>",
        linkText: "Jag vill bli kontaktad - klicka här",
        linkUrl: "https://www.volkswagenbilforsakring.se/service_leads",
      },
    ],
  },
  {
    brand: "volkswagen transport",
    cards: [
      {
        type: "accessories",
        imageUrl: "https://tbo2.vwgroup.se/api/api/File/ID.4GTXIC0496.jpg",
        title: "Tillbehör Online",
        body: "Tillbehör från Volkswagen utvecklas parallellt med bilen och tillverkas i högkvalitativa material med moderna tillverkningsprocesser.<br/><br/>För att få ingå i Volkswagen Originaltillbehör genomgår alla produkter rigorösa tester som dessutom överstiger de lagstadgade normerna.",
        linkText: "Klicka här för att köpa tillbehör till din bil",
        linkUrl: "https://vwtransportbilartillbehor.se/vwtransportbilar",
      },
      {
        type: "insurance",
        imageUrl: "https://tbo2.vwgroup.se/api/api/File/CDC0907.jpeg",
        title: "Volkswagen Försäkring",
        body: "Volkswagen Försäkring är skräddarsydd för din Volkswagen. Har du inte vår försäkring och vill ta del av vårt erbjudande (gäller endast nya kunder)? Klicka nedan så kontaktar vi dig.<br/><br/><strong>Alltid med Volkswagen Försäkring:</strong><br/><ul><li>Lägre självrisk - 3 000 kr lägre självrisk vid skada som täcks av vagnskadegarantin</li><li>Trygga reparationer - alltid Originaldelar på en auktoriserad märkesverkstad</li><li>En maskin- och elektronikförsäkring som räcker länge - 10 år eller upp till 15 000 mil</li></ul>",
        linkText: "Jag vill bli kontaktad - klicka här",
        linkUrl:
          "https://www.volkswagentransportbilarforsakring.se/service_leads",
      },
    ],
  },
  {
    brand: "cupra",
    cards: [
      {
        type: "accessories",
        imageUrl: "https://tbo2.vwgroup.se/api/api/File/ID.4GTXIC0496.jpg",
        title: "Tillbehör Online",
        body: "Vi har ett brett sortiment av tillbehör speciellt framtagna för just din CUPRA.",
        linkText: "Klicka här för att köpa tillbehör till din bil",
        linkUrl: "https://seattillbehor.se/cupra",
      },
      {
        type: "insurance",
        imageUrl: "https://tbo2.vwgroup.se/api/api/File/CDC0907.jpeg",
        title: "Cupra Försäkring",
        body: "Cupra Försäkring är skräddarsydd för din Cupra. Har du inte vår försäkring och vill ta del av vårt erbjudande (gäller endast nya kunder)? Klicka nedan så kontaktar vi dig.<br/><br/><strong>Alltid med Cupra Försäkring:</strong><br/><ul><li>Lägre självrisk - 3 000 kr lägre självrisk vid skada som täcks av vagnskadegarantin</li><li>Trygga reparationer - alltid Originaldelar på en auktoriserad märkesverkstad</li><li>En maskin- och elektronikförsäkring som räcker länge - 10 år eller upp till 15 000 mil</li></ul>",
        linkText: "Jag vill bli kontaktad - klicka här",
        linkUrl: "https://www.cuprabilforsakring.se/service_leads",
      },
    ],
  },
  {
    brand: "seat",
    cards: [
      {
        type: "brand-card",
        imageUrl: "/assets/images/brand-cards/seat-card.png",
        title: "Dela upp köpet på 12 månader",
        body: "Med SEAT Kortet Visa kan du dela upp din service, verkstadskostnader och andra inköp hos din SEAT-återförsäljare på 12 månader utan ränta och avgifter.<br/><br/>Du får dessutom poäng på alla inköp, oavsett vad du handlar. Du får 15 öre i rabatt per liter drivmedel du tankar på Preems bemannade stationer.  SEAT Kortet Visa har ingen årsavgift.",
        linkText: "Ansök här",
        linkUrl: "https://www.seat.se/shopping-tools/finansiering/forsakring",
      },
      {
        type: "accessories",
        imageUrl: "https://tbo2.vwgroup.se/api/api/File/ID.4GTXIC0496.jpg",
        title: "Tillbehör Online",
        body: "Vi har ett brett sortiment av tillbehör speciellt framtagna för just din SEAT.",
        linkText: "Klicka här för att köpa tillbehör till din bil",
        linkUrl: "https://seattillbehor.se/seat",
      },
      {
        type: "insurance",
        imageUrl: "https://tbo2.vwgroup.se/api/api/File/CDC0907.jpeg",
        title: "SEAT Försäkring",
        body: "SEAT Försäkring är skräddarsydd för din SEAT. Har du inte vår försäkring och vill ta del av vårt erbjudande (gäller endast nya kunder)? Klicka nedan så kontaktar vi dig.<br/><br/><strong>Alltid med SEAT Försäkring:</strong><br/><ul><li>Lägre självrisk - 3 000 kr lägre självrisk vid skada som täcks av vagnskadegarantin</li><li>Trygga reparationer - alltid Originaldelar på en auktoriserad märkesverkstad</li><li>En maskin- och elektronikförsäkring som räcker länge - 10 år eller upp till 15 000 mil</li></ul>",
        linkText: "Jag vill bli kontaktad - klicka här",
        linkUrl: "https://www.seatbilforsakring.se/service_leads",
      },
    ],
  },
];
