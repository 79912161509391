import { Component } from "@angular/core";
import { PagingService } from "src/app/services/paging.service";

@Component({
  selector: "app-footer-base",
  templateUrl: "./footer-base.component.html",
  styleUrls: [],
})
export class FooterBaseComponent {
  constructor(public pagingService: PagingService) {}
}
