import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const expandAnimation = trigger("openClose", [
  state("open", style({ height: "*", opacity: 1 })),
  state(
    "closed",
    style({
      height: "0px",
      "padding-top": 0,
      "padding-bottom": 0,
      opacity: 0,
      overflow: "hidden",
    }),
  ),
  transition("open => closed", [animate("0.3s ease-in-out")]),
  transition("closed => open", [animate("0.3s ease-in-out")]),
]);
