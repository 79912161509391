import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-page-container",
  templateUrl: "./page-container.component.html",
})
export class PageContainerComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() isLoading: boolean;

  constructor() {}

  ngOnInit(): void {}
}
