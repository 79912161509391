import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  PageFormIdEnum,
  businessProposalInitStep,
} from "src/app/classes/enums";
import { BusinessProposalService } from "src/app/services/business-proposal.service";
import { FeatureAppService } from "src/app/services/feature-app.service";
import { FormService } from "src/app/services/form.service";
import { IconService } from "src/app/services/icon.service";
import { MappingService } from "src/app/services/mapping.service";
import { PagingService } from "src/app/services/paging.service";
import { FooterPopupService } from "src/app/services/footer-popup.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
})
export class FooterComponent implements OnInit, OnDestroy {
  public PageFormIdEnum = PageFormIdEnum;

  constructor(
    public formService: FormService,
    public pagingService: PagingService,
    public translateService: TranslateService,
    public mappingService: MappingService,
    private footerPopupService: FooterPopupService,
    public iconService: IconService,
    public featureAppService: FeatureAppService,
    private businessProposalService: BusinessProposalService,
  ) {}

  isSummaryOpen = false;

  private subscription: Subscription | null = null;

  ngOnInit(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = this.footerPopupService.view$.subscribe({
      next: (s) => {
        if (!s) this.isSummaryOpen = false;
        else this.isSummaryOpen = s[0] === "summary";
      },
    });
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed)
      this.subscription.unsubscribe();
    this.subscription = null;
  }

  onNavigation(): void {
    if ("parentIFrame" in window) {
      (window as any).parentIFrame.scrollToOffset(0, 0);
    }
    document.getElementById("vbo-header").scrollIntoView(true);
  }

  toggleOpen(): void {
    if (!this.isSummaryOpen) this.footerPopupService.open("summary");
    else this.footerPopupService.close();
  }

  nextButtonTooltip(): string {
    if (
      this.pagingService.formStepLocation ===
        PageFormIdEnum.chooseSpecifications &&
      !!this.formService.basePackagesResponse &&
      !!this.formService.externalTyreUrlResponse?.externalTyreUrl &&
      !this.formService.chooseSpecificationsForm.valid
    ) {
      return this.translateService.instant("external-tyre-tooltip");
    }
    if (
      this.pagingService.formStepLocation ===
        PageFormIdEnum.chooseSpecifications &&
      !!this.formService.basePackagesResponse &&
      !this.formService.selectedBasePackages.length
    ) {
      return this.translateService.instant("missing-selection-tooltip");
    }
    return "";
  }

  isBackBtnInvalid(): boolean {
    const isBusinessProposalActiveAndStepLessThanInit =
      this.businessProposalService.isBusinessProposalActive &&
      this.pagingService.formStepLocation <= businessProposalInitStep;

    if (
      !this.formService.isCurrentFormInitialized ||
      isBusinessProposalActiveAndStepLessThanInit
    ) {
      return true;
    }

    return false;
  }
}
