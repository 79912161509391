import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-registration-number-box",
  templateUrl: "./registration-number-box.component.html",
})
export class RegistrationNumberBoxComponent implements OnInit {
  @Input() id: string;
  @Input() parentForm: FormGroup<any>;
  @Input() formControlKey: string;
  @Input() placeholder?: string;
  @Input() maxLen?: number;
  @Input() clearable?: boolean;

  @Output() confirm$ = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  clear(): void {
    this.control.setValue("");
  }

  confirm(): void {
    this.confirm$.emit();
  }

  get control(): AbstractControl {
    return this.parentForm.get(this.formControlKey);
  }
}
