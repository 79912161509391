import { NgModule } from "@angular/core";
import { ButtonComponent } from "./button/button.component";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [ButtonComponent, CheckboxComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    ReactiveFormsModule,
  ],
  exports: [ButtonComponent, CheckboxComponent],
})
export class InputModule {}
