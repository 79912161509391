import { Pipe, PipeTransform } from "@angular/core";
import { PackageResponse, PackageTypeEnum } from "src/domain/client";
import { IProduct } from "../interfaces/gtm-interfaces";
import { PriceService } from "../services/price.service";

@Pipe({
  name: "packageProductMap",
})
export class PackageProductMapPipe implements PipeTransform {
  constructor(private priceService: PriceService) {}
  transform(packageResponse: PackageResponse, _brand: string): IProduct {
    return {
      item_name: packageResponse.name,
      item_id: packageResponse.id,
      affiliation: "VBO",
      price: this.priceService.determinePrice(packageResponse.price),
      item_category: this.getCategoryFromPackageType(packageResponse.type),
      quantity: 1,
    };
  }

  getCategoryFromPackageType(type: PackageTypeEnum): IProduct["item_category"] {
    switch (type) {
      case PackageTypeEnum.OriginalService:
      case PackageTypeEnum.BaseService:
        return "Service";

      case PackageTypeEnum.Windshield:
        return "Windscreen";

      case PackageTypeEnum.Wheel:
        return "Tire";

      case PackageTypeEnum.Troubleshooting:
        return "Troubleshooting";

      case PackageTypeEnum.DealerExtra:
        return "LocalAddOn";

      case PackageTypeEnum.WashAndRecond:
        return "Detailing";

      case PackageTypeEnum.TechUpdate:
        return "Recall";

      case PackageTypeEnum.PPS:
      case PackageTypeEnum.ServiceAdditional:
        return "AddOn";

      case PackageTypeEnum.Mobility:
        return "Mobility";

      case PackageTypeEnum.CheckInOption:
        return "CheckIn";

      case PackageTypeEnum.DropoffOption:
        return "Calendar";

      default:
        return "Service";
    }
  }
}
