import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (typeof FEATURE_APP_BASE_URL !== "undefined" && FEATURE_APP_BASE_URL) {
      var baseUrl = FEATURE_APP_BASE_URL;
      const newRequest = req.clone({
        url: `${baseUrl}${req.url}`,
      });
      // Pass the cloned request to the next interceptor or the backend
      return next.handle(newRequest);
    }

    return next.handle(req);
    // Clone the request and set the base URL
  }
}
