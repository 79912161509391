import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ThemePalette } from "@angular/material/core";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
})
export class CheckboxComponent implements OnInit {
  @Input() id: string;
  @Input() parentForm: FormGroup<any>;
  @Input() formControlKey: string;
  @Input() text?: string;
  @Input() checked?: boolean;
  @Input() name?: string;
  @Input() inline?: boolean;
  @Input() rounded?: boolean;
  @Input() size?: "xl" | "md" | "sm";
  @Input() color?: ThemePalette;

  constructor() {}

  ngOnInit() {}
}
