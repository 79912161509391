import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageResolverPipe } from "./image-resolver.pipe";
import { FirstToUpperPipe } from "./first-to-upper.pipe";
import { PricePipe } from "./price.pipe";
import { FloorPipe } from "./floor.pipe";

@NgModule({
  declarations: [ImageResolverPipe, FirstToUpperPipe, PricePipe, FloorPipe],
  imports: [CommonModule],
  exports: [ImageResolverPipe, FirstToUpperPipe, PricePipe, FloorPipe],
})
export class SharedPipesModule {}
