import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { FormService } from "../services/form.service";
import { PagingService } from "../services/paging.service";

@Injectable({
  providedIn: "root",
})
export class FormPositionGuard {
  constructor(
    private formService: FormService,
    private pagingService: PagingService,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const currentStep = this.pagingService.formStepLocation;
    if (!this.formService?.carInfoResponse?.chassiNumber) {
      this.pagingService.navigateToStep(1);
      return false;
    }
    if (currentStep > 1 && !this.formService?.activeWorkshopSystemsResponse) {
      this.pagingService.navigateToStep(1);
      return false;
    }
    if (currentStep > 2 && !this.formService?.dealersResponse) {
      this.pagingService.navigateToStep(1);
      return false;
    }
    if (route.routeConfig.path == "complete" && !this.formService.form.valid) {
      this.pagingService.navigateToStep(1);
      return false;
    }
    return true;
  }
}
