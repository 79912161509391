import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FormTypeEnum } from "src/app/classes/enums";
import {
  IChooseSpecificationsForm,
  ITroubleshootingForm,
  IWindscreenForm,
} from "src/app/interfaces/interfaces";
import { PackagesFilterPipe } from "src/app/pipes/packages-filter.pipe";
import { PackagesServiceFilterPipe } from "src/app/pipes/packages-service-filter.pipe";
import { BusinessProposalService } from "src/app/services/business-proposal.service";
import { FormService } from "src/app/services/form.service";
import {
  PackageTypeEnum,
  VehicleWarningLightsResponse,
  WorkshopSystemEnum,
} from "src/domain/client";

export function createChooseSpecificationsForm(
  formService: FormService,
  packageServiceFilterPipe: PackagesServiceFilterPipe,
  packageFilterPipe: PackagesFilterPipe,
  businessProposalService: BusinessProposalService
) {
  const packages = !!formService.basePackagesResponse
    ? formService.basePackagesResponse
    : [];
  const servicePackages = packageServiceFilterPipe.transform(packages);
  const serviceOnlySelectedButNoPackages =
    formService.serviceTypeSelection.length === 1 &&
    formService.serviceTypeSelection.includes(WorkshopSystemEnum.Service) &&
    !servicePackages.length;
  const otherIsRequired =
    formService.serviceTypeSelection.includes(WorkshopSystemEnum.Other) ||
    serviceOnlySelectedButNoPackages;
  const newForm = new FormGroup<IChooseSpecificationsForm>(
    {
      other: new FormControl(
        "",
        otherIsRequired
          ? [Validators.required, Validators.minLength(5)]
          : undefined
      ),
    },
    formService.secureStateOnChanges(formService)
  );
  if (
    !!formService.externalTyreUrlResponse?.externalTyreUrl &&
    formService.serviceTypeSelection.length > 1
  ) {
    newForm.setValidators([
      formService.secureStateOnChanges(formService),
      formService.validateMinOneContainer(formService),
    ]);
  }

  // INIT SERVICE FORM PART
  if (
    formService.serviceTypeSelection.includes(WorkshopSystemEnum.Service) &&
    !!servicePackages.length
  ) {
    const tmpServiceForm = new FormGroup({});
    formService.initSpecificFormFromPackages(
      tmpServiceForm,
      packageServiceFilterPipe.transform(packages),
      businessProposalService.isBusinessProposalActive &&
        businessProposalService.proposalServices.Service
    );
    newForm.addControl(FormTypeEnum.ServiceForm, tmpServiceForm);
  }

  // INIT TROUBLESHOOTING AND REPAIRS PART
  if (
    formService.serviceTypeSelection.includes(
      WorkshopSystemEnum.Troubleshooting
    )
  ) {
    const tmpTroubleshootingForm = new FormGroup<ITroubleshootingForm>({
      helpText: new FormControl(),
      file: new FormControl(),
      icons: new FormGroup({}),
    });
    formService.initSpecificFormFromPackages(
      tmpTroubleshootingForm,
      packageFilterPipe.transform(packages, PackageTypeEnum.Troubleshooting),
      businessProposalService.isBusinessProposalActive &&
        businessProposalService.proposalServices.Troubleshooting
    );
    newForm.addControl(
      FormTypeEnum.TroubleshootingForm,
      tmpTroubleshootingForm
    );
    formService.warningLightsResponse.forEach(
      (light: VehicleWarningLightsResponse) =>
        (
          (newForm.controls.troubleshootingForm as FormGroup).controls
            .icons as FormGroup
        ).addControl(light.warningLightId.toString(), new FormControl(false))
    );
  }

  // INIT WINDSCREEN
  if (
    formService.serviceTypeSelection.includes(WorkshopSystemEnum.Windscreen)
  ) {
    const tmpWindscreenForm = new FormGroup<IWindscreenForm>({
      insurance: new FormControl(null, [Validators.required]),
      file: new FormControl(),
      date: new FormControl(),
      place: new FormControl(),
      reason: new FormControl(),
      damage: new FormControl(),
    });
    formService.initSpecificFormFromPackages(
      tmpWindscreenForm,
      packageFilterPipe.transform(packages, PackageTypeEnum.Windshield),
      businessProposalService.isBusinessProposalActive &&
        businessProposalService.proposalServices.Windscreen
    );
    newForm.addControl(FormTypeEnum.WindscreenForm, tmpWindscreenForm);
  }

  // INIT WHEEL
  if (formService.serviceTypeSelection.includes(WorkshopSystemEnum.Wheel)) {
    const tmpWheelForm = new FormGroup({});
    formService.initSpecificFormFromPackages(
      tmpWheelForm,
      packageFilterPipe.transform(packages, PackageTypeEnum.Wheel),
      businessProposalService.isBusinessProposalActive &&
        businessProposalService.proposalServices.Wheel
    );
    newForm.addControl(FormTypeEnum.WheelForm, tmpWheelForm);
  }

  // INIT TECHUPDATE
  if (
    formService.serviceTypeSelection.includes(WorkshopSystemEnum.TechUpdate)
  ) {
    const tmpTechUpdateForm = new FormGroup({});
    formService.initSpecificFormFromPackages(
      tmpTechUpdateForm,
      packageFilterPipe.transform(packages, PackageTypeEnum.TechUpdate),
      businessProposalService.isBusinessProposalActive &&
        businessProposalService.proposalServices.TechUpdate
    );
    newForm.addControl(FormTypeEnum.TechUpdateForm, tmpTechUpdateForm);
  }
  return newForm;
}
