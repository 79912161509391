import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IconService } from "src/app/services/icon.service";

@Component({
  selector: "app-view-more",
  templateUrl: "./view-more.component.html",
})
export class ViewMoreComponent implements OnInit {
  @Input() current: number;
  @Input() total: number;

  @Output() clicked = new EventEmitter<void>();

  constructor(public iconService: IconService) {}

  ngOnInit(): void {}
}
