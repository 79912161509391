import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import {
  ICalendarEvent,
  NgAddToCalendarService,
} from "src/libs/ng-add-to-calendar";
import { Moment } from "moment";
import { FormService } from "src/app/services/form.service";
import { TrackingService } from "src/app/services/tracking.service";
import {
  SwaggerException,
  Client,
  BookingDamageReportRequest,
  CreateBookingRequest,
  BookingCustomerRequest,
  PropertyEnum,
  BookingResourceRequest,
  CreateBookingErrorResponse,
  BookingErrorEnum,
} from "src/domain/client";
import { FileService } from "src/app/services/file.service";
import { DatePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { TotalPricePipe } from "src/app/pipes/total-price.pipe";
import { PageBaseComponent } from "../../base/page-base/page-base.component";
import { BusinessProposalService } from "src/app/services/business-proposal.service";

interface IBookingError {
  title: string;
  subtitle: string;
  returnText: string;
  returnToPage: number;
  returnButtonAbove: boolean;
}

@Component({
  selector: "app-complete",
  templateUrl: "./complete.component.html",
})
export class CompleteComponent extends PageBaseComponent implements OnInit {
  public orderId: string = null;

  public googleCalendar: SafeUrl;
  public outlookCalendar: SafeUrl;
  public outlookLive: SafeUrl;
  public iCalendar: SafeUrl;
  public yahooCalendar: SafeUrl;

  public newEvent: ICalendarEvent;

  bookingError: IBookingError = null;

  constructor(
    public formService: FormService,
    public translateService: TranslateService,
    private trackingService: TrackingService,
    private addToCalendarService: NgAddToCalendarService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private fileService: FileService,
    private totalPricePipe: TotalPricePipe,
    private businessProposalService: BusinessProposalService
  ) {
    super();
    if (!this.formService.form.valid) {
      return;
    }
    this.newEvent = {
      // Event title
      title: "Verkstadsbesök " + formService.carInfoResponse.registrationNumber,
      // Event start date
      start: formService.timeSelectionForm.controls.date.value,
      // Event duration (IN MINUTES)
      duration: 60,
      // Event Address (optional)
      address:
        this.formService.selectedWorkshop.street +
        ", " +
        this.formService.selectedWorkshop.city,
      // Event Description (optional)
      description: this.generateBookingDescription(),
    };
    this.googleCalendar = this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(
        this.addToCalendarService.calendarType.google,
        this.newEvent
      )
    );

    this.outlookCalendar = this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(
        this.addToCalendarService.calendarType.outlook,
        this.newEvent
      )
    );

    this.outlookLive = this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(
        this.addToCalendarService.calendarType.outlookLive,
        this.newEvent
      )
    );

    this.iCalendar = this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(
        this.addToCalendarService.calendarType.iCalendar,
        this.newEvent
      )
    );

    this.yahooCalendar = this.sanitizer.bypassSecurityTrustUrl(
      this.addToCalendarService.getHrefFor(
        this.addToCalendarService.calendarType.yahoo,
        this.newEvent
      )
    );
  }

  ngOnInit(): void {
    if (!this.formService.form.valid) return;
    if (!!this.formService.isBookingMadeSuccessful) {
      this.state = this.ComponentStateEnum.Initialized;
      return;
    }
    this.state = this.ComponentStateEnum.Loading;
    const windscreenForm =
      this.formService.chooseSpecificationsForm?.controls?.windscreenForm;
    let damageReport: BookingDamageReportRequest | null = null;
    let bookingTime = this.formService.timeSelectionForm.controls.date
      .value as Date;
    if (!!windscreenForm) {
      try {
        damageReport = new BookingDamageReportRequest({
          damageDate: windscreenForm.get("date").value
            ? (windscreenForm.get("date").value as unknown as Moment)
                .utc(true)
                .toDate()
            : null,
          description: windscreenForm.get("damage").value,
          insuranceCompanyId: parseInt(windscreenForm.controls.insurance.value),
          location: windscreenForm.get("place").value,
          reason: windscreenForm.get("reason").value,
        });
      } catch (e) {
        console.log("fields missing from damage report");
      }
    }

    const customer = new BookingCustomerRequest({
      firstName: this.formService.contactInformationForm.get("firstName").value,
      lastName: this.formService.contactInformationForm.get("lastName").value,
      email: this.formService.contactInformationForm.get("email").value,
      message: this.formService.contactInformationForm.get("other").value,
      phone: this.formService.contactInformationForm.get("phone").value,
      sendSms: this.formService.contactInformationForm.get("smsChecked").value,
    });

    const usesTimeSlot =
      this.formService.selectedDropoffOption.properties?.includes(
        PropertyEnum.TimeSlot
      );

    const bookingResource = this.getResourceRequest(bookingTime, usesTimeSlot);
    const allSelectedPackages = this.formService.allSelectedPackages;

    new Client()
      .apiV2Booking(
        new CreateBookingRequest({
          chassiNumber: this.formService.carInfoResponse.chassiNumber,
          odometer: this.formService.milageValue * 10,
          dealerNumber: this.formService.selectedWorkshop.number,
          bookingCustomer: customer,
          bookingDamageReport:
            !!damageReport &&
            !!Object.values(damageReport).length ===
              !!Object.keys(damageReport).length
              ? damageReport
              : null,
          time: bookingTime,
          usesTimeSlot,
          selectedPackages: allSelectedPackages,
          fileIds: this.fileService.fileIds,
          campaignCode: this.formService.hasValidCampaignCode
            ? this.formService.campaignResponse?.name
            : null,
          warningLightIds: this.formService.selectedLights.map(
            (v) => v.warningLightId
          ),
          customerMessage:
            this.formService.contactInformationForm.get("other").value,
          troubleShootingMessage:
            this.formService.chooseSpecificationsForm?.controls
              ?.troubleshootingForm?.controls?.helpText?.value,
          bookingResource,
          successfulRecallCheck:
            this.formService.techUpdateStatus?.successfulRecallCheck,
          businessProposalId:
            this.businessProposalService.businessProposal$.value
              ?.businessProposalId,
        })
      )
      .then((response) => {
        this.orderId = response.orderId;
        this.state = this.ComponentStateEnum.Initialized;
        this.formService.isBookingMadeSuccessful = true;
        this.bookingError = null;
        try {
          this.trackingService.trackPurchaseCompleteEcom(
            Math.floor(
              this.totalPricePipe.transform(
                this.formService.allSelectedPackages
              )
            ),
            this.formService
              .getBaseSelectionAsProducts()
              .concat(this.formService.getAdditionalSelectionAsProducts())
              .concat(
                this.formService.getSelectedTimeSelectionJobsAsProducts()
              ),
            this.orderId,
            this.formService.contactInformationForm.controls.discountCode?.value
          );
        } catch (e) {
          console.log("tracking complete failed");
        }
      })
      .catch((reason: CreateBookingErrorResponse | SwaggerException) => {
        this.state = this.ComponentStateEnum.ApiCallFailed;
        this.bookingError = this.parseCreateBookingError(reason);
      });
  }

  returnClicked() {
    if (!!this.bookingError) {
      this.formService.invalidateForm(this.bookingError.returnToPage - 1);
    }
  }

  private parseCreateBookingError(
    reason: CreateBookingErrorResponse | SwaggerException
  ): IBookingError {
    if (reason instanceof CreateBookingErrorResponse) {
      const timeslotErrors = [
        BookingErrorEnum.SelectedDayUnavailable,
        BookingErrorEnum.NoTimeSlotFoundForTimeSlotBooking,
        BookingErrorEnum.SelectedDropoffOptionIsNotAvailableThatDay,
        BookingErrorEnum.SelectedTimeUnavailable,
      ];
      return timeslotErrors.includes(reason.bookingError)
        ? {
            title: "request-fail.timeslot.title",
            subtitle: "",
            returnText: "request-fail.timeslot.return",
            returnToPage: 6,
            returnButtonAbove: true,
          }
        : null;
    }

    return null;
  }

  private generateBookingDescription(): string {
    let parts = [];
    let workshops = this.formService.serviceTypeSelection;
    let translatedWorkshops = workshops.map((workshop) =>
      this.translateService.instant(
        "workshopSystemEnum." + workshop.toLocaleLowerCase()
      )
    );

    let packageDescription = "";
    const products = this.formService.getSelectedTimeSelectionJobsAsProducts();

    products.forEach((product) => {
      const selected = this.formService.selectedTimePackages.find(
        (j) => j.packageId === +product.item_id
      );
      if (!selected) return;

      const selectedPackage = this.formService.basePackagesResponse.find(
        (p) => +p.id == selected.packageId
      );
      if (!selectedPackage) return;

      packageDescription = selectedPackage.description.shortDescription;
    });

    if (translatedWorkshops) {
      parts.push(translatedWorkshops.join(", "));
    }
    if (packageDescription) {
      parts.push(packageDescription);
    }

    return parts.join(",\n");
  }

  private getResourceRequest(
    bookingTime: Date,
    usesTimeSlot: boolean
  ): BookingResourceRequest | null {
    const selectedDate = this.formService.selectedWorkshopDate?.date;

    if (!selectedDate) {
      return null;
    }

    const dateFormatted = this.datePipe.transform(selectedDate, "yyyy-MM-dd");
    const { start, end, mechanic } = this.getResourceStartEnd(
      bookingTime,
      usesTimeSlot
    );
    return new BookingResourceRequest({
      customerAllowedToWait: true,
      usesTimeSlot,
      date: dateFormatted,
      startDateTime: start,
      endDateTime: end,
      resourceGroupId: mechanic,
    });
  }

  private getResourceStartEnd(
    bookingTime: Date,
    useTimeSlot: boolean
  ): { start?: Date; end?: Date; mechanic: string } {
    const emptyResult = { start: null, end: null, mechanic: null };

    if (!useTimeSlot || !this.formService.selectedWorkshopTime) {
      const firstMechanicOfDay =
        this.formService.selectedWorkshopDate?.timeSlots[0]
          ?.mechanicDurations[0]?.mechanicId;
      return {
        start: null,
        end: null,
        mechanic: firstMechanicOfDay || "Unknown",
      };
    }

    const start = bookingTime;

    const { mechanicId, duration } =
      this.formService.selectedWorkshopTime?.mechanicDurations[0];

    if (!mechanicId || !duration) {
      return emptyResult;
    }

    const hours = parseInt(duration.split(":")[0], 10);
    const minutes = parseInt(duration.split(":")[1], 10);

    const durationInMilliseconds = hours * 60 * 60 * 1000 + minutes * 60 * 1000;

    const end = new Date(bookingTime.getTime() + durationInMilliseconds);

    return { start, end, mechanic: mechanicId };
  }
}
