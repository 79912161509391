// import { environment } from "src/environments/environment";

// https://app-vgsprod-sl-d-01.azurewebsites.net/api/v1/proposal/e26d2000-93d1-4d5b-a722-08dce2a4a0f6

export default class ServiceLinkClientBase {
  public getBaseUrl(data: string, baseUrl: string): string {
    data;
    baseUrl;
    // return "http://127.0.0.1:4010";
    return "https://app-vgsprod-sl-d-01.azurewebsites.net";
  }

  // TODO change
  // public getBaseUrl(data: string, baseUrl: string): string {
  //   if (typeof FEATURE_APP_BASE_URL !== "undefined" && FEATURE_APP_BASE_URL) {
  //     var baseUrl = FEATURE_APP_BASE_URL;
  //     return baseUrl + environment.apiUrl;
  //   }
  //   return location.protocol + "//" + location.host + environment.apiUrl;
  // }
}
