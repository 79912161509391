import { Component } from "@angular/core";
import { BusinessProposalService } from "src/app/services/business-proposal.service";
import { BusinessProposalRejectionReasonEnum } from "src/domain/client";

@Component({
  selector: "app-rejected-proposal-success",
  templateUrl: "./rejected-proposal-success.component.html",
})
export class RejectedProposalComponent {
  RejectedReasonEnum = BusinessProposalRejectionReasonEnum;
  rejectedReason: BusinessProposalRejectionReasonEnum | undefined;
  rejectedReasonComment: string | undefined;

  constructor(private businessProposalService: BusinessProposalService) {
    this.rejectedReason = this.businessProposalService.rejectedReason;
    this.rejectedReasonComment =
      this.businessProposalService.rejectedReasonComment;
  }
}
