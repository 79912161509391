import {
  Directive,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { FeatureAppService } from "../services/feature-app.service";

@Directive({
  selector: "[appIsFeatureApp]",
})
export class IsFeatureAppDirective implements OnInit, OnChanges {
  @Input({ required: false }) appIsFeatureApp: boolean | "" = "";

  private hasView = null;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureAppService: FeatureAppService,
  ) {}

  private updateView() {
    const isFeatureApp = this.appIsFeatureApp === "" ? true : this.appIsFeatureApp;
    const condition = this.featureAppService.isFeatureApp === isFeatureApp;
    if (condition && this.hasView !== true) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!condition && this.hasView !== false) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  ngOnChanges() {
    this.updateView();
  }
  ngOnInit() {
    this.updateView();
  }
}

@Directive({
  selector: "[appIsNotFeatureApp]",
})
export class IsNotFeatureAppDirective implements OnInit, OnChanges {
  @Input({ required: false }) appIsNotFeatureApp: boolean | "" = "";

  private hasView = null;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureAppService: FeatureAppService,
  ) {}

  private updateView() {
    const isFeatureApp = this.appIsNotFeatureApp === "" ? false : !this.appIsNotFeatureApp;
    const condition = this.featureAppService.isFeatureApp === isFeatureApp;
    if (condition && this.hasView !== true) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!condition && this.hasView !== false) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }

  ngOnChanges() {
    this.updateView();
  }
  ngOnInit() {
    this.updateView();
  }
}
