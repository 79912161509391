import { Component, Input } from "@angular/core";
import { UrlService } from "src/app/services/url.service";

@Component({
  selector: "app-information-content",
  templateUrl: "./information-content.component.html",
})
export class InformationContentComponent {
  @Input({ required: true }) title!: string;
  constructor(public urlService: UrlService) {}
}
