import { Pipe, PipeTransform } from "@angular/core";
import { PackageResponse, PackageTagEnum } from "src/domain/client";
import { FormService } from "../services/form.service";

@Pipe({
  name: "priceDisplay",
})
export class PriceDisplayPipe implements PipeTransform {
  constructor(private formService: FormService) {}
  transform(pkg: PackageResponse): number {
    const currentSelection = this.formService.selectedBasePackages.find(
      (s) => s.packageId === parseInt(pkg.id),
    );
    return pkg.subPackages
      .map((p) => {
        return !!p.tags.includes(PackageTagEnum.Removable)
          ? !!currentSelection && currentSelection.subPackageIds.includes(p.id)
            ? Math.floor(p.price?.discounted)
            : 0
          : Math.floor(p.price?.discounted);
      })
      .reduce((a, b) => a + b);
  }
}
