import { Pipe, PipeTransform } from "@angular/core";
import { PackageResponse, SelectedPackagesRequest } from "src/domain/client";
import { FormService } from "../services/form.service";

@Pipe({
  name: "filterSubpackages",
})
export class FilterSubpackagesPipe implements PipeTransform {
  constructor(public formService: FormService) {}
  transform(
    pkg: PackageResponse,
    selected: SelectedPackagesRequest[],
  ): PackageResponse[] {
    const selectedMatch = selected.find((x) => x.packageId == +pkg.id);
    if (!!selectedMatch) {
      return pkg.subPackages.filter((x) =>
        selectedMatch.subPackageIds.includes(x.id),
      );
    }
    return pkg.subPackages;
  }
}
