import { Component, Input, OnInit } from "@angular/core";
import { expandAnimation } from "src/app/animations/expand";
import { PriceService } from "src/app/services/price.service";
import { PackageResponse, PriceTypeEnum } from "src/domain/client";

@Component({
  selector: "app-show-proposal-pps-packages",
  templateUrl: "./show-proposal-pps-packages.component.html",
  animations: [expandAnimation],
})
export class ShowProposalPpsPackagesComponent implements OnInit {
  @Input() packages: PackageResponse[];
  public PriceTypeEnum: typeof PriceTypeEnum = PriceTypeEnum;

  constructor(public priceService: PriceService) {}

  ngOnInit(): void {}
}
