import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ThemePalette } from "@angular/material/core";

@Component({
  selector: "app-radio-button",
  templateUrl: "./radio-button.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class RadioButtonComponent implements OnInit {
  @Input() value: string;
  @Input() checked: boolean;
  @Input() size?: "xl" | "md" | "sm";
  @Input() color?: ThemePalette;

  constructor() {}

  ngOnInit(): void {}
}
