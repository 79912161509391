import { Pipe, PipeTransform } from "@angular/core";
import {
  PackageResponse,
  PackageTagEnum,
  PackageTypeEnum,
} from "src/domain/client";

@Pipe({
  name: "packagesServiceFilter",
})
export class PackagesServiceFilterPipe implements PipeTransform {
  transform(packages: PackageResponse[]): PackageResponse[] {
    if (!packages) return [];
    return packages
      .filter(
        (j: PackageResponse) =>
          j.type === PackageTypeEnum.OriginalService ||
          j.type === PackageTypeEnum.BaseService,
      )
      .sort((a, b) => {
        const aIsRecommended =
          a.tags.includes(PackageTagEnum.Recommended) &&
          a.type == PackageTypeEnum.BaseService;
        const bIsRecommended =
          b.tags.includes(PackageTagEnum.Recommended) &&
          b.type == PackageTypeEnum.BaseService;
        if (aIsRecommended === bIsRecommended) return 0;
        return aIsRecommended ? -1 : 1;
      });
  }
}
