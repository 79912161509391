import { OverlayContainer } from "@angular/cdk/overlay";

export class CustomOverlayContainer extends OverlayContainer {
  protected _createContainer(): void {
    const container: HTMLDivElement = document.createElement("div");
    container.classList.add("app-overlay-container");
    const element: Element | null = document.getElementById("vboAppRoot");
    if (element !== null) {
      element.appendChild(container);
      this._containerElement = container;
    }
  }
}
