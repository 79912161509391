import { Component, Input, OnInit } from "@angular/core";
import { expandAnimation } from "src/app/animations/expand";
import { InfoCardTypesEnum } from "src/app/classes/enums";
import { IconService } from "src/app/services/icon.service";

@Component({
  selector: "app-info-card",
  templateUrl: "./info-card.component.html",
  animations: [expandAnimation],
})
export class InfoCardComponent implements OnInit {
  public collapsed = !!(window.innerWidth < 992);
  public isMobile = !!(window.innerWidth < 992);

  @Input() header: string;
  @Input() body: string;
  @Input() icon?: string;
  @Input() type?: InfoCardTypesEnum;
  @Input() toggler = true;
  @Input() alwaysOpen? = false;

  InfoCardTypesEnum = InfoCardTypesEnum;

  constructor(public iconService: IconService) {}

  ngOnInit(): void {}

  onToggle(): void {
    this.isMobile = false;
    this.collapsed = !this.collapsed;
  }
}
