import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DialogTypeEnum } from "src/app/classes/enums";
import { CarBrandPipe } from "src/app/pipes/car-brand.pipe";
import { FormService } from "src/app/services/form.service";
import { PagingService } from "src/app/services/paging.service";
import { TrackingService } from "src/app/services/tracking.service";
import {
  SwaggerException,
  Client,
  CampaignByCodeRequest,
  CampaignResponse,
  DiscountTypeEnum,
} from "src/domain/client";
import { DialogComponent } from "../../layout/dialog/dialog.component";
import { PageBaseComponent } from "../../base/page-base/page-base.component";

enum DiscountCodeValidationStateEnum {
  NotValidated,
  IsValidating,
  ValidationFailed,
  Validated,
}
@Component({
  selector: "app-contact-information",
  templateUrl: "./contact-information.component.html",
})
export class ContactInformationComponent
  extends PageBaseComponent
  implements OnInit, OnDestroy
{
  DiscountTypeEnum = DiscountTypeEnum;
  discountCodeValidationState = DiscountCodeValidationStateEnum.NotValidated;
  DiscountCodeValidationStateEnum = DiscountCodeValidationStateEnum;

  public DialogType: typeof DialogTypeEnum = DialogTypeEnum;
  constructor(
    public formService: FormService,
    private trackingService: TrackingService,
    public pagingService: PagingService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private carBrandPipe: CarBrandPipe
  ) {
    super();
    if (
      !!this.formService.contactInformationForm.pristine &&
      !this.formService.contactInformationForm.valid
    )
      this.trackingService.trackContactFormStarted();
    this.formService.contactInformationForm.markAsPristine();
    this.formService.previousFormValue =
      this.formService.contactInformationForm.value;
  }
  ngOnDestroy(): void {
    if (!this.formService.contactInformationForm.pristine)
      this.trackingService.trackContactFormSubmitted(
        this.formService.contactInformationForm.get("smsChecked").value
      );
    this.cleanUp();
  }

  ngOnInit(): void {
    const val = this.formService.chooseSpecificationsForm.controls.other?.value;
    if (!!val && !this.formService.contactInformationForm.controls.other?.value)
      this.formService.contactInformationForm.controls.other.setValue(val);
  }

  validateDiscountCode(): void {
    this.discountCodeValidationState =
      DiscountCodeValidationStateEnum.IsValidating;
    new Client()
      .apiV2DealerGetCampaignCode(
        new CampaignByCodeRequest({
          dealerNo: this.formService.selectedWorkshop.number,
          campaignCode:
            this.formService.contactInformationForm.get("discountCode").value,
        })
      )
      .then(
        (response: CampaignResponse) => {
          if (!response) {
            this.discountCodeValidationState =
              DiscountCodeValidationStateEnum.ValidationFailed;
          }
          this.discountCodeValidationState =
            DiscountCodeValidationStateEnum.Validated;
          this.formService.campaignResponse = response;
          this.formService.contactInformationForm.controls.discountCode.disable();
        },
        (reason: SwaggerException) => {
          this.formService.campaignResponse = null;
          if (reason.status === 503) {
            this.pagingService.navigateToServiceUnavailable();
          }
          this.discountCodeValidationState =
            DiscountCodeValidationStateEnum.ValidationFailed;
        }
      );
  }

  clearDiscountCode() {
    this.formService.contactInformationForm.controls.discountCode.enable();
    this.formService.campaignResponse = null;
    this.discountCodeValidationState =
      DiscountCodeValidationStateEnum.NotValidated;
  }

  openModal(type: DialogTypeEnum, event?: MouseEvent): void {
    event?.preventDefault();
    const title = this.translateService.instant("dialog." + type);
    document.getElementById("vbo-header").scrollIntoView(true);
    const ref = this.dialog.open(DialogComponent, {
      data: {
        componentName: type,
        title,
        brand: this.carBrandPipe.transform(this.formService.carInfoResponse),
      },
      maxWidth: "600px",
      width: "90%",
    });

    const _obs = ref.afterClosed();
  }
}
