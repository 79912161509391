import { Pipe, PipeTransform } from "@angular/core";
import {
  PackageResponse,
  PackageTagEnum,
  PackageTypeEnum,
} from "src/domain/client";

@Pipe({
  name: "packagesFilter",
})
export class PackagesFilterPipe implements PipeTransform {
  transform(
    packages: PackageResponse[],
    type: PackageTypeEnum,
  ): PackageResponse[] {
    return packages
      .filter((j: PackageResponse) => j.type === type)
      .sort((a, b) => {
        const aIsRecommended = a.tags.includes(PackageTagEnum.Recommended);
        const bIsRecommended = b.tags.includes(PackageTagEnum.Recommended);
        if (aIsRecommended === bIsRecommended) return 0;
        return aIsRecommended ? -1 : 1;
      });
  }
}
