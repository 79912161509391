import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-progress-bar",
  templateUrl: "./progress-bar.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ProgressBarComponent implements OnInit {
  // Progressbar max is 100, use (100/7)*current step.
  @Input() value: number;
  @Input() thick: boolean;

  constructor() {}

  ngOnInit(): void {}
}
