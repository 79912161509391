import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ILocation, IViewPair } from "src/app/interfaces/interfaces";
import { ExtenderService } from "src/app/services/extender.service";
import { FeatureAppService } from "src/app/services/feature-app.service";
import { FormService } from "src/app/services/form.service";
import { PackageService } from "src/app/services/package.service";
import { PagingService } from "src/app/services/paging.service";
import { TrackingService } from "src/app/services/tracking.service";
import {
  DealerResponse,
  DealerRequest,
  SwaggerException,
  Client,
  WorkshopSystemEnum,
} from "src/domain/client";
import { PageBaseComponent } from "../../base/page-base/page-base.component";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-find-dealer",
  templateUrl: "./find-dealer.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class FindDealerComponent
  extends PageBaseComponent
  implements OnInit, OnDestroy
{
  public locations: ILocation[] = null;
  public selectedLocation: ILocation = null;
  public clientLocation: ILocation = null;

  public hasSearched = false;
  public autoSortEnabled = true;

  public isDealersMissing = false;
  public isSingleDealer = false;

  public sortingData: IViewPair[] = [
    {
      value: "distance",
      view: this.translateService.instant("find-dealer.distance"),
    },
    {
      value: "time",
      view: this.translateService.instant("find-dealer.time"),
    },
  ];

  public sortingSubscription: Subscription = null;
  public workshopSubscription: Subscription = null;

  constructor(
    public formService: FormService,
    private translateService: TranslateService,
    public extenderService: ExtenderService,
    public pagingService: PagingService,
    private trackingService: TrackingService,
    private packageService: PackageService,
    private featureAppService: FeatureAppService
  ) {
    super();
    this.formService.findDealerForm.markAsPristine();
    this.formService.previousFormValue = this.formService.findDealerForm.value;
  }
  ngOnDestroy(): void {
    if (!!this.workshopSubscription) this.workshopSubscription.unsubscribe();
    if (!!this.sortingSubscription) this.sortingSubscription.unsubscribe();
    if (!this.formService.findDealerForm.pristine)
      this.trackingService.trackWorkshopChosen(
        this.formService.selectedWorkshop,
        this.hasSearched
      );
    if (!!this.isDealersMissing) this.formService.dealersResponse = null;
    this.cleanUp();
  }

  ngOnInit(): void {
    if (!this.formService.providerIds?.length)
      this.formService.providerIds =
        this.featureAppService.getFeatureAppDealers();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        this.clientLocation = {
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        };
        if (this.workshops) this.sortByPosition(this.clientLocation);
        this.formService.findDealerForm.patchValue(
          { sorting: "distance" },
          { onlySelf: true, emitEvent: false }
        );
      });
    } else {
      console.log("geolocation not supported");
    }
    if (!!this.workshops) {
      if (!!this.formService.selectedWorkshop) {
        this.selectedLocation = {
          longitude: this.formService.selectedWorkshop.longitude,
          latitude: this.formService.selectedWorkshop.latitude,
        };
        this.onLocationSelect(this.selectedLocation, true);
      }
      this.state = this.ComponentStateEnum.Initialized;
      this.formService.findDealerForm.get("workshop").markAsTouched();
      this.init();
    } else {
      this.state = this.ComponentStateEnum.Loading;
      new Client()
        .apiV2DealerGetAll(
          new DealerRequest({
            dealerNoList: !!this.formService.providerIds.length
              ? this.formService.providerIds
              : null,
            chassiNumber: this.formService.carInfoResponse.chassiNumber,
            workshopSystemFilter: this.formService.serviceTypeSelection,
          })
        )
        .then(
          (response: DealerResponse[]) => {
            this.formService.dealersResponse = response;
            this.state = this.ComponentStateEnum.Initialized;
            this.init();
          },
          (reason: SwaggerException) => {
            if (reason.status === 404 || reason.status === 503)
              this.pagingService.navigateToServiceUnavailable();
            this.state = this.ComponentStateEnum.ApiCallFailed;
          }
        );
    }
  }

  getAllDealers(): void {
    new Client()
      .apiV2DealerGetAll(
        new DealerRequest({
          dealerNoList: null,
          chassiNumber: this.formService.carInfoResponse.chassiNumber,
          workshopSystemFilter: this.formService.serviceTypeSelection,
        })
      )
      .then(
        (response: DealerResponse[]) => {
          this.formService.dealersResponse = response;
          this.state = this.ComponentStateEnum.Initialized;
          this.init();
        },
        (reason: SwaggerException) => {
          if (reason.status === 404 || reason.status === 503)
            this.pagingService.navigateToServiceUnavailable();
          this.state = this.ComponentStateEnum.ApiCallFailed;
        }
      );
  }

  init(): void {
    this.isDealersMissing = !this.formService.dealersResponse.length;
    if (!!this.isDealersMissing) {
      return;
    }
    if (this.formService.dealersResponse.length === 1) {
      this.isSingleDealer = true;
      this.formService.findDealerForm.controls.workshop.patchValue(
        this.formService.dealersResponse[0].number
      );
      this.selectedLocation = {
        longitude: this.formService.selectedWorkshop.longitude,
        latitude: this.formService.selectedWorkshop.latitude,
      };
      this.onLocationSelect(this.selectedLocation, true);
    }
    if (this.clientLocation != null) {
      this.sortByPosition(this.clientLocation);
    } else {
      this.sortByAvailableTime();
    }
    this.locations = this.getDealerLocations();
    this.setSubscriptions();
  }

  onLocationSelect(location: ILocation, pristine?: boolean): void {
    this.selectedLocation = location;
    this.formService.findDealerForm.patchValue(
      { sorting: "distance" },
      { onlySelf: true, emitEvent: false }
    );
    this.sortByPosition(this.selectedLocation);
    const workshop = this.formService.dealersResponse.find((d) => {
      const loc: ILocation = {
        longitude: d.longitude,
        latitude: d.latitude,
      };
      return JSON.stringify(loc) === JSON.stringify(location);
    });
    if (!!workshop) {
      const control = this.formService.findDealerForm.get("workshop");
      if (!pristine) {
        control.markAsTouched();
        control.markAsDirty();
      }
      control.patchValue(workshop.number);
      if (!pristine) control.updateValueAndValidity();
    }
    this.formService.dealersResponse = [...this.workshops];
  }
  onLocationSearch(location: ILocation): void {
    this.hasSearched = true;
    this.autoSortEnabled = false;
    if (this.formService.findDealerForm.get("sorting").value === "distance")
      this.sortByPosition(location);
  }
  onLocationIdle(location: ILocation): void {
    if (!this.autoSortEnabled) return;
    if (this.formService.findDealerForm.get("sorting").value === "distance")
      this.sortByPosition(location);
  }

  setSubscriptions(): void {
    this.workshopSubscription =
      this.formService.findDealerForm.controls.workshop.valueChanges.subscribe({
        next: (workshopId: string) => {
          const workshop = this.workshops.find((w) => w.number === workshopId);
          this.selectedLocation = {
            longitude: workshop.longitude,
            latitude: workshop.latitude,
          };
          if (
            !this.formService.serviceTypeSelection.includes(
              WorkshopSystemEnum.Service
            )
          )
            return;
          this.formService.findDealerForm.setErrors({
            settingServiceTypes: true,
          });
          this.packageService
            .refreshEnabledServiceTypes(workshopId)
            .then(() => {
              this.formService.findDealerForm.setErrors({
                settingServiceTypes: null,
              });
              this.formService.findDealerForm.updateValueAndValidity();
            });
        },
      });
    this.sortingSubscription =
      this.formService.findDealerForm.controls.sorting.valueChanges.subscribe({
        next: (value: string) => {
          this.formService.findDealerForm.controls.sorting.markAsPristine();
          switch (value) {
            case "distance":
              if (!this.clientLocation && !this.selectedLocation) return;
              this.sortByPosition(
                !!this.clientLocation
                  ? this.clientLocation
                  : this.selectedLocation
              );
              break;
            case "time":
              this.sortByAvailableTime();
              break;
          }
        },
      });
  }

  getDealerLocations(): ILocation[] {
    return this.workshops.map((w: DealerResponse) => {
      return {
        longitude: w.longitude,
        latitude: w.latitude,
      };
    });
  }

  get workshops(): DealerResponse[] {
    return this.formService.dealersResponse;
  }

  get sortingControl(): FormControl<string> {
    return this.formService.findDealerForm?.controls?.sorting;
  }

  get sortingViewPair(): IViewPair {
    return this.sortingData.find(
      (d) => d.value === this.formService.findDealerForm.controls.sorting.value
    );
  }

  availableTimeLabel(id: string): string {
    let system = this.formService.dealersResponse
      .find((w) => w.number === id)
      .dealerWorkshopSystemResponses.find((s) =>
        this.formService.serviceTypeSelection.includes(s.workshopSystem)
      );
    if (!system)
      system = this.formService.dealersResponse.find((w) => w.number === id)
        .dealerWorkshopSystemResponses[0];
    if (system.workshopSystem == WorkshopSystemEnum.Wheel) return "short-tyre";
    if (system.daysUntilNextFreeTime === null) return "short";
    if (system.daysUntilNextFreeTime > 14) return "long";
    if (system.daysUntilNextFreeTime > 7) return "medium";
    return "short";
  }

  workshopDistance(id: string): string {
    const workshop = this.workshops.find((w) => w.number === id);
    if (!workshop || !this.clientLocation) return "";
    const distM = Math.floor(
      this.dist(this.clientLocation, {
        longitude: workshop.longitude,
        latitude: workshop.latitude,
      }) *
        2 *
        6371
    );
    return distM > 1000
      ? Math.floor(distM / 1000) + " mil"
      : Math.floor(distM / 100) + " km";
  }

  sortByPosition(position: ILocation): void {
    this.workshops.sort(
      (w1, w2) =>
        this.dist(position, {
          longitude: w1.longitude,
          latitude: w1.latitude,
        }) -
        this.dist(position, {
          longitude: w2.longitude,
          latitude: w2.latitude,
        })
    );
  }

  sortByAvailableTime(): void {
    const selection = this.formService.serviceTypeSelection;
    const matcher = !!selection.includes(WorkshopSystemEnum.Service)
      ? WorkshopSystemEnum.Service
      : !!selection.includes(WorkshopSystemEnum.TechUpdate)
      ? WorkshopSystemEnum.Service // This does not exist at the moment so use service times
      : !!selection.includes(WorkshopSystemEnum.Wheel)
      ? WorkshopSystemEnum.Wheel
      : !!selection.includes(WorkshopSystemEnum.Troubleshooting)
      ? WorkshopSystemEnum.Troubleshooting
      : !!selection.includes(WorkshopSystemEnum.Windscreen)
      ? WorkshopSystemEnum.Windscreen
      : WorkshopSystemEnum.Service;

    this.workshops.sort((w1, w2) => {
      const w1DealerWorkshopSystem = w1.dealerWorkshopSystemResponses.find(
        (d) => d.workshopSystem === matcher
      );
      const w2DealerWorkshopSystem = w2.dealerWorkshopSystemResponses.find(
        (d) => d.workshopSystem === matcher
      );
      if (!w1DealerWorkshopSystem || !w2DealerWorkshopSystem) {
        return 0;
      }
      const w1FirstAvailableTime = w1DealerWorkshopSystem.firstAvailableTime;
      const w2FirstAvailableTime = w2DealerWorkshopSystem.firstAvailableTime;
      return w1FirstAvailableTime === w2FirstAvailableTime
        ? 0
        : w1FirstAvailableTime < w2FirstAvailableTime
        ? -1
        : 1;
    });
  }

  private dist(from: ILocation, to: ILocation): number {
    const p = 0.017453292519943295; // Math.PI / 180
    const c = Math.cos;
    const a =
      0.5 -
      c((to.latitude - from.latitude) * p) / 2 +
      (c(from.latitude * p) *
        c(to.latitude * p) *
        (1 - c((to.longitude - from.longitude) * p))) /
        2;
    return (12742 * Math.asin(Math.sqrt(a))) / 100; // 2 * R; R = 6371 km
  }
}
