import { Component, OnDestroy, OnInit } from "@angular/core";
import { ExtenderService } from "src/app/services/extender.service";
import { FormService } from "src/app/services/form.service";
import { PagingService } from "src/app/services/paging.service";
import { PriceService } from "src/app/services/price.service";
import { TrackingService } from "src/app/services/tracking.service";
import {
  SwaggerException,
  Client,
  PackageTagEnum,
  PackageRequest,
  PackageResponse,
  PackageTypeEnum,
  BusinessProposalStatusEnum,
  ErrorResponse,
} from "src/domain/client";
import { PageBaseComponent } from "../../base/page-base/page-base.component";
import { FormGroup } from "@angular/forms";
import { PackageProductMapPipe } from "src/app/pipes/package-product-map.pipe";
import { BusinessProposalService } from "src/app/services/business-proposal.service";
import { createExtraServicesForm } from "./extra-services-form.helper";
import { forkJoin, from, Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Component({
  selector: "app-extra-services",
  templateUrl: "./extra-services.component.html",
})
export class ExtraServicesComponent
  extends PageBaseComponent
  implements OnInit, OnDestroy
{
  public PackageTagEnum: typeof PackageTagEnum = PackageTagEnum;

  constructor(
    public formService: FormService,
    private pagingService: PagingService,
    public extenderService: ExtenderService,
    private trackingService: TrackingService,
    public priceService: PriceService,
    private packageProductMapPipe: PackageProductMapPipe,
    private businessProposalService: BusinessProposalService
  ) {
    super();
    this.formService.extraServicesForm.markAsPristine();
    this.formService.previousFormValue =
      this.formService.extraServicesForm.value;
  }
  ngOnDestroy(): void {
    if (
      !this.formService.extraServicesForm.pristine &&
      !this.trackingService.hasTrackedAddonsOnce
    )
      this.trackingService.trackAddonChosen(
        this.formService.getAdditionalSelectionAsProducts()
      );
    this.cleanUp();
  }

  private handleGetPackageError(
    err: SwaggerException | ErrorResponse
  ): Observable<PackageResponse[]> {
    if (err.status === 404) {
      return of([]);
    }
    return throwError(err);
  }

  ngOnInit(): void {
    this.formService.extraServicesForm.markAsPristine();
    if (
      !!this.formService.additionalPackagesResponse &&
      !this.formService.extraServiceLoadedInStep4
    ) {
      this.state = this.ComponentStateEnum.Initialized;
      this.trackListView();
      return;
    }

    this.state = this.ComponentStateEnum.Loading;
    var observables = [];
    observables.push(
      from(
        new Client().apiV2PackageWashAndRecond(
          new PackageRequest({
            chassiNumber: this.formService.carInfoResponse.chassiNumber,
            dealerNumber: this.formService.selectedWorkshop.number,
            odometer: this.formService.milageValue * 10,
          })
        )
      ).pipe(catchError(this.handleGetPackageError))
    );
    observables.push(
      from(
        new Client().apiV2PackageServiceAdditional(
          new PackageRequest({
            chassiNumber: this.formService.carInfoResponse.chassiNumber,
            dealerNumber: this.formService.selectedWorkshop.number,
            odometer: this.formService.milageValue * 10,
          })
        )
      ).pipe(catchError(this.handleGetPackageError))
    );
    observables.push(
      from(
        new Client().apiV2PackageDealerExtra(
          new PackageRequest({
            chassiNumber: this.formService.carInfoResponse.chassiNumber,
            dealerNumber: this.formService.selectedWorkshop.number,
            odometer: this.formService.milageValue * 10,
          })
        )
      ).pipe(catchError(this.handleGetPackageError))
    );

    forkJoin(observables)
      .toPromise()
      .then(
        (response: PackageResponse[][]) => {
          const packages: PackageResponse[] = [].concat.apply([], response);
          var proposal = this.businessProposalService.businessProposal$.value;
          this.formService.extraServiceLoadedInStep4 = false;
          this.formService.additionalPackagesResponse = packages
            .filter((x) => !!x)
            .map((x) => {
              const matchingProposalPackage =
                this.formService.proposalExtraServicePackages.find((p) => {
                  if (x.packageExternalId) {
                    return p.packageExternalId === x.packageExternalId;
                  }
                  return p.id === x.id;
                });

              if (
                matchingProposalPackage &&
                proposal?.businessProposalStatus !==
                  BusinessProposalStatusEnum.Expired
              ) {
                if (matchingProposalPackage.isSubCategory) {
                  const subPackages = x.subPackages.map((subPkg) => {
                    const index = matchingProposalPackage.subPackages.findIndex(
                      (s) => s.packageExternalId === subPkg.id
                    );
                    if (index !== -1) {
                      return matchingProposalPackage.subPackages[index];
                    }

                    return subPkg;
                  });

                  return new PackageResponse({
                    ...matchingProposalPackage,
                    subPackages,
                  });
                }
                // return the proposal package in case it differs from the fetched package. Proposal package has priority.
                return matchingProposalPackage;
              }

              return x;
            });

          // See if any proposal package does not exist among packages. If not, add it
          this.formService.proposalExtraServicePackages.forEach((p) => {
            const matchingPackage =
              this.formService.additionalPackagesResponse.find(
                (x) => x.id === p.id
              );

            if (!matchingPackage) {
              // Add package to front of array. Logical since it'll be checked
              this.formService.additionalPackagesResponse.unshift(p);
            }
          });

          this.trackingService.hasTrackedAddonsOnce = false;
          this.trackListView();
          this.initForm();
          this.state = this.ComponentStateEnum.Initialized;
        },
        (reason: SwaggerException) => {
          if (reason.status === 404 || reason.status === 503)
            this.pagingService.navigateToServiceUnavailable();
          this.state = this.ComponentStateEnum.ApiCallFailed;
        }
      );
  }

  initForm(): void {
    const newForm = createExtraServicesForm(this.formService);
    this.formService.updateForm(newForm);
    this.formService.extraServicesForm.markAsTouched();
  }

  extraServicesParentForm(id: string): FormGroup<any> {
    return this.formService.extraServicesForm.get(id) as FormGroup<any>;
  }

  get packages(): PackageResponse[] {
    return !!this.formService.additionalPackagesResponse
      ? this.formService.additionalPackagesResponse
      : [];
  }

  get serviceAdditionalPackages(): PackageResponse[] {
    // "PPS" type packages might exist on proposal packages, add them here
    const packages = this.packages.filter((j: PackageResponse) =>
      [PackageTypeEnum.ServiceAdditional, PackageTypeEnum.PPS].includes(j.type)
    );
    return !!packages ? packages : [];
  }

  get dealerExtraPackages(): PackageResponse[] {
    const packages = this.packages.filter(
      (j: PackageResponse) =>
        j.type === PackageTypeEnum.DealerExtra ||
        j.type === PackageTypeEnum.WashAndRecond
    );
    return !!packages ? packages : [];
  }
  private trackListView(): void {
    this.trackingService.trackItemListViewed(
      "Step5",
      [].concat.apply(
        [],
        this.formService.additionalPackagesResponse.map((x) =>
          [
            this.packageProductMapPipe.transform(
              x,
              this.formService.carInfoResponse?.brandName
            ),
          ].concat(
            x.subPackages.map((sp) =>
              this.packageProductMapPipe.transform(
                sp,
                this.formService.carInfoResponse?.brandName
              )
            )
          )
        )
      )
    );
  }
}
