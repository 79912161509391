import { NgZone } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { createChooseSpecificationsForm } from "src/app/components/views/choose-specifications/choose-specification-form.helper";
import { createExtraServicesForm } from "src/app/components/views/extra-services/extra-services-form.helper";
import {
  IWindscreenWizardForm,
  IVboBookingForm,
} from "src/app/interfaces/interfaces";
import { PackagesFilterPipe } from "src/app/pipes/packages-filter.pipe";
import { PackagesServiceFilterPipe } from "src/app/pipes/packages-service-filter.pipe";
import { FormService } from "src/app/services/form.service";
import { PagingService } from "src/app/services/paging.service";
import {
  IVehicleWarningLightsResponse,
  IVehicleSearchResponse,
  IActiveWorkshopSystemResponse,
  IDealerResponse,
  IPackageResponse,
  IInsuranceCompanyResponse,
  ICalendarTimeSlotResponse,
  ICampaignResponse,
  IGetTechUpdateStatusResponse,
  IExternalTyreUrlResponse,
  VehicleWarningLightsResponse,
  VehicleSearchResponse,
  ActiveWorkshopSystemResponse,
  DealerResponse,
  PackageResponse,
  InsuranceCompanyResponse,
  CalendarDayTimeSlotResponse,
  CampaignResponse,
  GetTechUpdateStatusResponse,
  ExternalTyreUrlResponse,
  PackageTypeEnum,
} from "src/domain/client";
import { BusinessProposalService } from "./services/business-proposal.service";

interface ISerializedFormService {
  form: Omit<RawFormValue, "chooseSpecificationsForm" | "extraServicesForm"> &
    Partial<
      Pick<RawFormValue, "chooseSpecificationsForm" | "extraServicesForm">
    >;
  windscreenWizardForm: ReturnType<
    FormGroup<IWindscreenWizardForm>["getRawValue"]
  >;
  responses: {
    warningLightsResponse?: IVehicleWarningLightsResponse[];
    carInfoResponse?: IVehicleSearchResponse;
    activeWorkshopSystemsResponse?: IActiveWorkshopSystemResponse[];
    dealersResponse?: IDealerResponse[];
    basePackagesResponse?: IPackageResponse[];
    insuranceProvidersResponse?: IInsuranceCompanyResponse[];
    additionalPackagesResponse?: IPackageResponse[];
    availableTimesResponse?: ICalendarTimeSlotResponse[];
    campaignResponse?: ICampaignResponse;
    techUpdateStatus?: IGetTechUpdateStatusResponse;
    externalTyreUrlResponse?: IExternalTyreUrlResponse;
  };
  state: {
    providerIds: string[];
    rejectedIsOnlyTyre: boolean;
    searchedByRegno: boolean;
    previousFormValue: any;
    isStateDialogOpen: boolean;
    segment: number;
    isBookingMadeSuccessful: boolean;
  };
  responseStateRefs: {
    selectedWorkshopDate: number;
    selectedWorkshopTime: number;
  };
}

type RawFormValue = ReturnType<FormGroup<IVboBookingForm>["getRawValue"]>;

declare global {
  var _devHelpers: DevHelpers | undefined;
}

const AUTOLOAD = Symbol("AUTOLOAD");
export class DevHelpers {
  constructor(
    public formService: FormService,
    public pagingService: PagingService,
    public packagesServiceFilterPipe: PackagesServiceFilterPipe,
    public packagesFilterPipe: PackagesFilterPipe,
    public zone: NgZone,
    public businessProposalService: BusinessProposalService
  ) {
    this.loadFormState(AUTOLOAD);
    Object.assign(window, {
      loadFormState: this.loadFormState.bind(this),
      saveFormState: this.saveFormState.bind(this),
    });
  }

  loadFormState(maybeName?: string | typeof AUTOLOAD) {
    const isAutoload = maybeName === AUTOLOAD;
    const name = isAutoload ? "AUTOLOAD" : maybeName || "DEFAULT";
    const stored = window.sessionStorage.getItem(
      `VBO_DEV_STORED_STATE_${name}`
    );
    if (stored == null) {
      if (!isAutoload) console.error(`Cannot find stored state \`${name}\``);
      return;
    }
    let serialized: any;
    let formStep: unknown;
    try {
      ({ formStep, ...serialized } = JSON.parse(stored));
    } catch (e) {
      console.error(e);
      if (isAutoload) {
        console.error(
          `Failed to parse json when deserializing stored state\nThe \`${name}\` state was cleared to prevent repeat issues`
        );
        window.sessionStorage.removeItem(`VBO_DEV_STORED_STATE_${name}`);
      } else {
        console.error(
          `Failed to parse json when deserializing stored state \`${name}\``
        );
      }
      return;
    }
    try {
      this.zone.run(() => {
        this.deserializeForm(serialized);
        if (typeof formStep === "number")
          this.pagingService.navigateToStep(formStep);
      });
    } catch (e) {
      console.error(e);
      const errMsg = `Failed to deserialize \`${name}\`\nThe webpage is currently in an undefined state. A reload is recommended.`;
      if (isAutoload) {
        window.sessionStorage.removeItem(`VBO_DEV_STORED_STATE_${name}`);
        console.error(
          `${errMsg}\nThe \`${name}\` state was cleared to prevent repeat issues`
        );
      } else {
        console.error(errMsg);
      }
    }
  }

  saveFormState(name?: string) {
    name = name || "DEFAULT";
    const toStore = {
      ...this.serializeForm(),
      formStep: this.pagingService.formStepLocation,
    };
    console.log(toStore);
    window.sessionStorage.setItem(
      `VBO_DEV_STORED_STATE_${name}`,
      JSON.stringify(toStore)
    );
  }

  serializeForm(): ISerializedFormService {
    const rawformValue: ISerializedFormService["form"] =
      this.formService.form.getRawValue();
    if (this.pagingService.formStepLocation < 4) {
      delete rawformValue["chooseSpecificationsForm"];
    }
    if (this.pagingService.formStepLocation < 5) {
      delete rawformValue["extraServicesForm"];
    }
    return {
      form: rawformValue,
      windscreenWizardForm: this.formService.windscreenWizardForm.getRawValue(),
      responses: {
        warningLightsResponse: this.formService.warningLightsResponse?.map(
          (r) => r.toJSON()
        ),
        carInfoResponse: this.formService.carInfoResponse?.toJSON(),
        activeWorkshopSystemsResponse:
          this.formService.activeWorkshopSystemsResponse?.map((r) =>
            r instanceof ActiveWorkshopSystemResponse ? r.toJSON() : r
          ),
        dealersResponse: this.formService.dealersResponse?.map((r) =>
          r.toJSON()
        ),
        basePackagesResponse: this.formService.basePackagesResponse?.map((r) =>
          r.toJSON()
        ),
        insuranceProvidersResponse:
          this.formService.insuranceProvidersResponse?.map((r) => r.toJSON()),
        additionalPackagesResponse:
          this.formService.additionalPackagesResponse?.map((r) => r.toJSON()),
        availableTimesResponse: this.formService.availableTimesResponse?.map(
          (r) => r.toJSON()
        ),
        campaignResponse: this.formService.campaignResponse?.toJSON(),
        techUpdateStatus: this.formService.techUpdateStatus?.toJSON(),
        externalTyreUrlResponse:
          this.formService.externalTyreUrlResponse?.toJSON(),
      },
      state: {
        providerIds: this.formService.providerIds,
        rejectedIsOnlyTyre: this.formService.rejectedIsOnlyTyre,
        searchedByRegno: this.formService.searchedByRegno,
        previousFormValue: this.formService.previousFormValue,
        isStateDialogOpen: this.formService.isStateDialogOpen,
        segment: this.formService.segment,
        isBookingMadeSuccessful: this.formService.isBookingMadeSuccessful,
      },
      responseStateRefs: {
        selectedWorkshopDate:
          this.formService.availableTimesResponse?.indexOf(
            this.formService.selectedWorkshopDate
          ) ?? -1,
        selectedWorkshopTime:
          this.formService.selectedWorkshopDate?.timeSlots?.indexOf(
            this.formService.selectedWorkshopTime
          ) ?? -1,
      },
    };
  }

  deserializeForm({
    form,
    windscreenWizardForm,
    responses,
    state,
    responseStateRefs,
  }: ISerializedFormService) {
    this.formService.warningLightsResponse =
      responses.warningLightsResponse?.map((r) =>
        VehicleWarningLightsResponse.fromJS(r)
      ) ?? null;
    this.formService.carInfoResponse = responses.carInfoResponse
      ? VehicleSearchResponse.fromJS(responses.carInfoResponse)
      : null;
    this.formService.activeWorkshopSystemsResponse =
      responses.activeWorkshopSystemsResponse?.map((r) =>
        ActiveWorkshopSystemResponse.fromJS(r)
      ) ?? null;
    this.formService.dealersResponse =
      responses.dealersResponse?.map((r) => DealerResponse.fromJS(r)) ?? null;
    this.formService.basePackagesResponse =
      responses.basePackagesResponse?.map((r) => PackageResponse.fromJS(r)) ??
      null;
    this.formService.insuranceProvidersResponse =
      responses.insuranceProvidersResponse?.map((r) =>
        InsuranceCompanyResponse.fromJS(r)
      ) ?? null;
    this.formService.additionalPackagesResponse =
      responses.additionalPackagesResponse?.map((r) =>
        PackageResponse.fromJS(r)
      ) ?? null;
    this.formService.availableTimesResponse =
      responses.availableTimesResponse?.map((r) =>
        CalendarDayTimeSlotResponse.fromJS(r)
      ) ?? null;
    this.formService.campaignResponse = responses.campaignResponse
      ? CampaignResponse.fromJS(responses.campaignResponse)
      : null;
    this.formService.techUpdateStatus = responses.techUpdateStatus
      ? GetTechUpdateStatusResponse.fromJS(responses.techUpdateStatus)
      : null;
    this.formService.externalTyreUrlResponse = responses.externalTyreUrlResponse
      ? ExternalTyreUrlResponse.fromJS(responses.externalTyreUrlResponse)
      : null;
    Object.assign(this, state);
    if (
      responseStateRefs.selectedWorkshopDate >= 0 &&
      this.formService.availableTimesResponse.length
    ) {
      this.formService.selectedWorkshopDate =
        this.formService.availableTimesResponse[
          responseStateRefs.selectedWorkshopDate
        ] ?? null;
    }
    if (
      responseStateRefs.selectedWorkshopTime >= 0 &&
      this.formService.selectedWorkshopDate?.timeSlots?.length
    ) {
      this.formService.selectedWorkshopTime =
        this.formService.selectedWorkshopDate.timeSlots[
          responseStateRefs.selectedWorkshopTime
        ] ?? null;
    }
    const {
      extraServicesForm,
      chooseSpecificationsForm,
      timeSelectionForm,
      ...basicForm
    } = form;
    Object.entries(basicForm).forEach(([control, val]) => {
      try {
        this.formService.form.controls[control].setValue(val);
      } catch (err) {
        try {
          err.message = `${err.message} (${control})`;
        } catch {}
        throw err;
      }
    });
    if (chooseSpecificationsForm) {
      const newForm = createChooseSpecificationsForm(
        this.formService,
        this.packagesServiceFilterPipe,
        this.packagesFilterPipe,
        this.businessProposalService
      );
      newForm.setValue(chooseSpecificationsForm);
      this.formService.form.removeControl("chooseSpecificationsForm");
      this.formService.form.addControl("chooseSpecificationsForm", newForm);
    }
    if (extraServicesForm) {
      const newForm = createExtraServicesForm(this.formService);
      newForm.setValue(extraServicesForm);
      this.formService.form.removeControl("extraServicesForm");
      this.formService.form.addControl("extraServicesForm", newForm);
    }
    if (this.formService.availableTimesResponse) {
      this.formService.initSpecificFormFromPackages(
        this.formService.timeSelectionForm,
        this.formService.basePackagesResponse.filter(
          (p) => p.type == PackageTypeEnum.DropoffOption
        )
      );
    }
    this.formService.form.controls.timeSelectionForm.setValue(
      timeSelectionForm
    );
    this.formService.windscreenWizardForm.setValue(windscreenWizardForm);
  }
}
