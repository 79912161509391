import { DealerPropertyEnum, WorkshopSystemEnum } from "src/domain/client";

export abstract class BaseIconService {
  public abstract registeredSvgIcons: string[];

  IsSvgIcon(icon: string): boolean {
    return !!this.registeredSvgIcons.filter((i) => i === icon).length;
  }

  IsUrl(icon: string): boolean {
    let url;
    try {
      url = new URL(icon);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  WorkshopSystemIcon(id: WorkshopSystemEnum): string {
    switch (id) {
      case WorkshopSystemEnum.Service:
        return "build_circle";
      case WorkshopSystemEnum.Troubleshooting:
        return "search";
      case WorkshopSystemEnum.Windscreen:
        return "windscreen";
      case WorkshopSystemEnum.Wheel:
        return "wheel";
      case WorkshopSystemEnum.TechUpdate:
        return "offline_bolt";
      case WorkshopSystemEnum.Other:
        return "chat_bubble_outline";
    }
  }

  WorkshopOtherSystemIcon(id: DealerPropertyEnum): string {
    switch (id) {
      case DealerPropertyEnum.Restaurant:
        return "restaurant";
      case DealerPropertyEnum.Gym:
        return "fitness_center";
      case DealerPropertyEnum.Europcar:
        return "europcar";
      case DealerPropertyEnum.MRF:
        return "workspace_premium";
      case DealerPropertyEnum.MachineWash:
        return "local_car_wash";
      case DealerPropertyEnum.Recond:
        return "brush";
    }
  }

  get techUpdateIcon(): string {
    return "offline_bolt";
  }

  get externalTyreIcon(): string {
    return "wheel";
  }

  get infoIcon(): string {
    return "info_outline";
  }

  get closeIcon(): string {
    return "close";
  }

  get arrowRightIcon(): string {
    return "keyboard_arrow_right";
  }

  get arrowLeftIcon(): string {
    return "keyboard_arrow_left";
  }

  get arrowUpIcon(): string {
    return "keyboard_arrow_up";
  }
  get arrowDownIcon(): string {
    return "keyboard_arrow_down";
  }

  get arrowBackIcon(): string {
    return "arrow_back";
  }

  get editIcon(): string {
    return "edit";
  }

  get cachedIcon(): string {
    return "cached";
  }

  get mapIcon(): string {
    return "map";
  }

  get placeIcon(): string {
    return "place";
  }

  get checkmarkIcon(): string {
    return "checked";
  }

  get checkedCircleIcon(): string {
    return "check_circle";
  }

  get warningIcon(): string {
    return "warning";
  }
}
