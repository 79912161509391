import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
})
export class LoadingComponent implements OnInit {
  @Input() text: string;
  // size in pixels. Will apply to width and height
  @Input() size?: number;

  constructor() {}

  ngOnInit(): void {}
}
