import { AfterViewInit, Component } from "@angular/core";
import { ConsentService } from "src/app/services/consent.service";
import { IconService } from "src/app/services/icon.service";
import { PagingService } from "src/app/services/paging.service";

@Component({
  selector: "app-cookie-information",
  templateUrl: "./cookie-information.component.html",
})
export class CookieInformationComponent implements AfterViewInit {
  constructor(
    private consentService: ConsentService,
    private pagingService: PagingService,
    public iconService: IconService,
  ) {}

  onAnchorClick(event: MouseEvent) {
    if (!(event.currentTarget instanceof HTMLAnchorElement)) return;
    const { currentTarget } = event;
    const path = currentTarget.href
      .substring(0, currentTarget.href.length - currentTarget.hash.length)
      .replace(/#$/, "");
    const curPath = location.href
      .substring(0, location.href.length - location.hash.length)
      .replace(/#$/, "");
    if (path !== curPath || currentTarget.hash.length <= 1) return;
    window.location.hash = currentTarget.hash;
  }

  ngAfterViewInit() {
    if (window.location.hash.length > 1) {
      const oldHash = window.location.hash;
      window.location.hash = "";
      window.location.hash = oldHash;
    }
  }

  openCookieModal() {
    this.consentService.openConsentDialog();
  }
}
