import { Component, OnInit } from "@angular/core";
import { FormService } from "src/app/services/form.service";
import { promoCardData, PromoCardProps } from "./promo-card-data";

@Component({
  selector: "app-upsales-container",
  templateUrl: "./upsales-container.component.html",
  styleUrls: ["./upsales-container.component.scss"],
})
export class UpsalesContainerComponent implements OnInit {
  dealerNumber: string;
  registrationNumber: string;
  brand: string;
  upsalesCards: PromoCardProps[] = [];

  constructor(public formService: FormService) {
    if (this.formService.selectedWorkshop && this.formService.carInfoResponse) {
      this.registrationNumber =
        this.formService.carInfoResponse?.registrationNumber;
      this.dealerNumber = this.formService.selectedWorkshop?.number;
      this.brand = this.formService.carInfoResponse?.brandName;
    }
  }

  ngOnInit(): void {
    if (this.brand)
      this.upsalesCards =
        promoCardData.find((data) => data.brand === this.brand.toLowerCase())
          ?.cards || [];

    this.upsalesCards.forEach((card) => {
      if (
        card.type === "accessories" &&
        this.dealerNumber &&
        this.registrationNumber
      ) {
        card.linkUrl =
          card.linkUrl +
          `?dealer=${this.dealerNumber}&regnr=${this.registrationNumber}&utm_source=vbo_tacksida&utm_medium=banner_link&utm_campaign=tbo_online&utm_term=vgs&utm_content=kop_tillbehor`;
      }
    });
  }
}
