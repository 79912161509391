import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FormService } from "src/app/services/form.service";
import { BrandEnum, DialogTypeEnum } from "src/app/classes/enums";
import { DialogComponent } from "../dialog/dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { PackageResponse, PackageTagEnum } from "src/domain/client";
import { IconService } from "src/app/services/icon.service";
import { CarBrandPipe } from "src/app/pipes/car-brand.pipe";

@Component({
  selector: "app-package-info",
  templateUrl: "./package-info.component.html",
})
export class PackageInfoComponent implements OnInit {
  public BrandEnum: typeof BrandEnum = BrandEnum;
  public DialogType: typeof DialogTypeEnum = DialogTypeEnum;
  public PackageTagEnum: typeof PackageTagEnum = PackageTagEnum;

  @Input() package: PackageResponse;

  mainPackage: PackageResponse;

  constructor(
    public formService: FormService,
    public dialog: MatDialog,
    public translateService: TranslateService,
    public iconService: IconService,
    private carBrandPipe: CarBrandPipe,
  ) {}

  ngOnInit(): void {
    this.setMainPackage();
  }

  setMainPackage(): void {
    this.mainPackage = this.package.subPackages.find((p) =>
      p.tags.includes(PackageTagEnum.MainPackage),
    );
  }

  openModal(
    type: DialogTypeEnum,
    title: string,
    brand: BrandEnum = null,
  ): void {
    const brandString = !!brand ? this.translateService.instant(brand) : "";
    const titleString = this.translateService.instant(title);
    document.getElementById("vbo-header").scrollIntoView(true);
    const ref = this.dialog.open(DialogComponent, {
      data: {
        componentName: type,
        title: !!brand
          ? brandString.charAt(0).toUpperCase() +
            brandString.slice(1) +
            " " +
            titleString
          : titleString,
        brand: this.carBrandPipe.transform(this.formService.carInfoResponse),
      },
      maxWidth: "600px",
      width: "90%",
    });

    const _obs = ref.afterClosed();
  }
}
