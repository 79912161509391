import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ExtenderService {
  private extendWithConst = 7;
  // Step 3
  public workshopLimit = 3;
  public extendedWorkshopLimitThreshold = 5;

  // Step 4
  public warningLightsLimit = 3;

  // Step 5
  public serviceAddonPackagesLimit = 5;
  public additionalServicePackagesLimit = 5;

  constructor() {}

  public adjustLimit(limit: number, total: number): number {
    if (limit < total) {
      if (limit + this.extendWithConst > total) limit = total;
      else limit += this.extendWithConst;
    } else {
      limit = 3;
    }
    return limit;
  }
}
