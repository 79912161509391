import { Injectable } from "@angular/core";
import {
  Client,
  PackageResponse,
  PackageRequest,
  PackageTypeEnum,
  WorkshopSystemEnum,
  SwaggerException,
  ErrorResponse,
} from "src/domain/client";
import { FormService } from "./form.service";
import { SelectedProposalService } from "../interfaces/interfaces";
import { forkJoin, from, Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PackageService {
  public enabledServiceTypes: PackageTypeEnum[] = [
    PackageTypeEnum.OriginalService,
    PackageTypeEnum.BaseService,
  ];

  constructor(private formService: FormService) {}

  public refreshEnabledServiceTypes(dealer: string): Promise<void> {
    return new Client()
      .apiV2PackageDetermineServiceType(
        new PackageRequest({
          chassiNumber: this.formService.carInfoResponse.chassiNumber,
          dealerNumber: dealer,
          odometer:
            this.formService.aboutVehicleForm.controls.milage?.value * 10,
          systems: this.formService.serviceTypeSelection,
        })
      )
      .then((types) => {
        this.enabledServiceTypes = types;
      });
  }

  public getOriginalService(): Promise<PackageResponse> {
    return new Client().apiV2PackageOriginalService(
      new PackageRequest({
        chassiNumber: this.formService.carInfoResponse.chassiNumber,
        dealerNumber: this.formService.selectedWorkshop.number,
        odometer: this.formService.aboutVehicleForm.controls.milage?.value * 10,
        systems: this.formService.serviceTypeSelection,
      })
    );
  }

  public getWheelPackages(): Promise<PackageResponse[]> {
    return new Client().apiV2PackageWheel(
      new PackageRequest({
        chassiNumber: this.formService.carInfoResponse.chassiNumber,
        dealerNumber: this.formService.selectedWorkshop.number,
        odometer: this.formService.aboutVehicleForm.controls.milage?.value * 10,
        systems: this.formService.serviceTypeSelection,
      })
    );
  }

  public getDropOffPackages() {
    return new Client().apiV2PackageDropOffOption(
      new PackageRequest({
        chassiNumber: this.formService.carInfoResponse.chassiNumber,
        dealerNumber: this.formService.selectedWorkshop.number,
        odometer: this.formService.aboutVehicleForm.controls.milage?.value * 10,
        systems: this.formService.serviceTypeSelection,
      })
    );
  }

  private handleGetPackageError(
    err: SwaggerException | ErrorResponse
  ): Observable<PackageResponse[]> {
    if (err.status === 404) {
      return of([]);
    }
    return throwError(err);
  }

  // Should probably not do like this, but task gets too big if we should refactor entire step 4
  public async getAllPackagesForStep4(
    selectedServices: SelectedProposalService | null = null
  ): Promise<PackageResponse[]> {
    let allObservables = [];
    allObservables.push(
      from(this.getDropOffPackages()).pipe(
        catchError(this.handleGetPackageError)
      )
    );
    if (
      this.formService.serviceTypeSelection.includes(
        WorkshopSystemEnum.Service
      ) &&
      !selectedServices?.Service
    ) {
      if (this.enabledServiceTypes.includes(PackageTypeEnum.BaseService))
        allObservables.push(
          from(
            new Client().apiV2PackageBaseServiceSuggestions(
              new PackageRequest({
                chassiNumber: this.formService.carInfoResponse.chassiNumber,
                dealerNumber: this.formService.selectedWorkshop.number,
                odometer:
                  this.formService.aboutVehicleForm.controls.milage?.value * 10,
                systems: this.formService.serviceTypeSelection,
              })
            )
          ).pipe(catchError(this.handleGetPackageError))
        );
      if (
        this.enabledServiceTypes.includes(PackageTypeEnum.OriginalService) &&
        !selectedServices?.Service
      )
        allObservables.push(
          from(
            new Client().apiV2PackageOriginalService(
              new PackageRequest({
                chassiNumber: this.formService.carInfoResponse.chassiNumber,
                dealerNumber: this.formService.selectedWorkshop.number,
                odometer:
                  this.formService.aboutVehicleForm.controls.milage?.value * 10,
                systems: this.formService.serviceTypeSelection,
              })
            )
          ).pipe(catchError(this.handleGetPackageError))
        );
    }
    if (
      this.formService.serviceTypeSelection.includes(
        WorkshopSystemEnum.Windscreen
      ) &&
      !selectedServices?.Windscreen
    ) {
      allObservables.push(
        from(
          new Client().apiV2PackageWindshield(
            new PackageRequest({
              chassiNumber: this.formService.carInfoResponse.chassiNumber,
              dealerNumber: this.formService.selectedWorkshop.number,
              odometer:
                this.formService.aboutVehicleForm.controls.milage?.value * 10,
              systems: this.formService.serviceTypeSelection,
            })
          )
        ).pipe(catchError(this.handleGetPackageError))
      );
    }
    if (
      this.formService.serviceTypeSelection.includes(
        WorkshopSystemEnum.Wheel
      ) &&
      !selectedServices?.Wheel
    ) {
      allObservables.push(
        from(
          new Client().apiV2PackageWheel(
            new PackageRequest({
              chassiNumber: this.formService.carInfoResponse.chassiNumber,
              dealerNumber: this.formService.selectedWorkshop.number,
              odometer:
                this.formService.aboutVehicleForm.controls.milage?.value * 10,
              systems: this.formService.serviceTypeSelection,
            })
          )
        ).pipe(catchError(this.handleGetPackageError))
      );
    }
    if (
      this.formService.serviceTypeSelection.includes(
        WorkshopSystemEnum.Troubleshooting
      ) &&
      !selectedServices?.Troubleshooting
    ) {
      allObservables.push(
        from(
          new Client().apiV2PackageTroubleshooting(
            new PackageRequest({
              chassiNumber: this.formService.carInfoResponse.chassiNumber,
              dealerNumber: this.formService.selectedWorkshop.number,
              odometer:
                this.formService.aboutVehicleForm.controls.milage?.value * 10,
              systems: this.formService.serviceTypeSelection,
            })
          )
        ).pipe(catchError(this.handleGetPackageError))
      );
    }
    if (
      this.formService.serviceTypeSelection.includes(
        WorkshopSystemEnum.TechUpdate
      ) &&
      !selectedServices?.TechUpdate
    ) {
      allObservables.push(
        from(
          new Client().apiV2PackageTechUpdate(
            new PackageRequest({
              chassiNumber: this.formService.carInfoResponse.chassiNumber,
              dealerNumber: this.formService.selectedWorkshop.number,
              odometer:
                this.formService.aboutVehicleForm.controls.milage?.value * 10,
              systems: this.formService.serviceTypeSelection,
            })
          )
        ).pipe(catchError(this.handleGetPackageError))
      );
    }
    return forkJoin(allObservables)
      .toPromise()
      .then((x) => {
        return [].concat.apply([], x);
      });
  }
}
