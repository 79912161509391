import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "firstToUpper",
})
export class FirstToUpperPipe implements PipeTransform {
  transform(value: string, ..._args: unknown[]): string {
    return value[0].toUpperCase() + value.slice(1);
  }
}
