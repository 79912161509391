import { Component, OnInit, Input } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-text-area",
  templateUrl: "./text-area.component.html",
})
export class TextAreaComponent implements OnInit {
  @Input() id: string;
  @Input() text: string;
  @Input() placeholder?: string;
  @Input() rows?: number;
  @Input() parentForm: FormGroup<any>;
  @Input() formControlKey: string;
  @Input() maxLength?: number;

  constructor() {}

  ngOnInit() {}

  get control(): AbstractControl {
    return this.parentForm.get(this.formControlKey);
  }
}
