import { TimeSelectionComponent } from "./../components/views/time-selection/time-selection.component";
import { ContactInformationComponent } from "./../components/views/contact-information/contact-information.component";
import { ExtraServicesComponent } from "./../components/views/extra-services/extra-services.component";
import { inject, NgModule } from "@angular/core";
import {
  PreloadAllModules,
  Router,
  RouterModule,
  Routes,
} from "@angular/router";
import { AboutVehicleComponent } from "../components/views/about-vehicle/about-vehicle.component";
import { WorkshopSystemSelectionComponent } from "../components/views/workshop-system-selection/workshop-system-selection.component";
import { ChooseSpecificationsComponent } from "../components/views/choose-specifications/choose-specifications.component";
import { ReviewComponent } from "../components/views/review/review.component";
import { CompleteComponent } from "../components/views/complete/complete.component";
import { MaintenanceComponent } from "../components/views/maintenance/maintenance.component";
import { FormPositionGuard } from "../guards/form-position.guard";
import { FindDealerComponent } from "../components/views/find-dealer/find-dealer.component";
import { RejectedProposalComponent } from "../components/views/rejected-proposal-success/rejected-proposal-success.component";
import { BusinessProposalGuard } from "../guards/business-proposal.guard";
import { FeatureAppService } from "../services/feature-app.service";
import { CookieInformationComponent } from "../components/views/cookie-information/cookie-information.component";
import { PersonalDataComponent } from "../components/views/personal-data/personal-data.component";
import { TermsOfUseComponent } from "../components/views/terms-of-use/terms-of-use.component";
import { FeatureService } from "../services/feature.service";

const childRoutes: Routes = [
  {
    path: "form/1",
    component: AboutVehicleComponent,
    data: { animationState: "Step1" },
  },
  {
    path: "form/2",
    component: WorkshopSystemSelectionComponent,
    data: { animationState: "Step2" },
    canActivate: [FormPositionGuard],
  },
  {
    path: "form/3",
    component: FindDealerComponent,
    data: { animationState: "Step3" },
    canActivate: [FormPositionGuard],
  },
  {
    path: "form/4",
    component: ChooseSpecificationsComponent,
    data: { animationState: "Step4" },
    canActivate: [FormPositionGuard],
  },
  {
    path: "form/5",
    component: ExtraServicesComponent,
    data: { animationState: "Step5" },
    canActivate: [FormPositionGuard],
  },
  {
    path: "form/6",
    component: TimeSelectionComponent,
    data: { animationState: "Step6" },
    canActivate: [FormPositionGuard],
  },
  {
    path: "form/7",
    component: ContactInformationComponent,
    data: { animationState: "Step7" },
    canActivate: [FormPositionGuard],
  },
  {
    path: "form/8",
    component: ReviewComponent,
    data: { animationState: "Step8" },
    canActivate: [FormPositionGuard],
  },
  {
    path: "complete",
    component: CompleteComponent,
    canActivate: [FormPositionGuard],
  },
  {
    path: "cookie-information",
    component: CookieInformationComponent,
  },
  {
    path: "terms-of-use",
    component: TermsOfUseComponent,
  },
  {
    path: "personal-data",
    component: PersonalDataComponent,
  },
  {
    path: "rejected-proposal",
    component: RejectedProposalComponent,
    canActivate: [BusinessProposalGuard],
  },
  {
    path: "unavailable",
    component: MaintenanceComponent,
  },
  {
    path: "service-link",
    loadChildren: () =>
      import("./service-link/service-link.module").then(
        (m) => m.ServiceLinkModule
      ),
    canActivate: [
      () => inject(FeatureService).isFeatureEnabled("service-link"),
    ],
  },
  {
    path: "**",
    redirectTo: "form/1",
  },
];

const brandRoutes: Routes = [
  {
    path: "vw",
    children: childRoutes,
  },
  {
    path: "audi",
    children: childRoutes,
  },
  {
    path: "seat",
    children: childRoutes,
  },
  {
    path: "skoda",
    children: childRoutes,
  },
  {
    path: "cupra",
    children: childRoutes,
  },
  {
    path: "porsche",
    children: childRoutes,
  },
  {
    path: "default",
    children: childRoutes,
  },
];

const routes: Routes = brandRoutes.concat([
  {
    path: "**",
    redirectTo: "default/form/1",
    pathMatch: "full",
  },
]);

// THIS IS SPECIAL TO MAKE FA WORK ON SUBDOMAINS
const rootRoutes: Routes = brandRoutes.concat([
  {
    path: "",
    children: routes,
  },
]);

@NgModule({
  imports: [
    RouterModule.forRoot(rootRoutes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: "disabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(
    private router: Router,
    private faService: FeatureAppService,
    private featureService: FeatureService
  ) {
    this.faService.isInitialized.subscribe(() => {
      this.updateRoutes();
    });
  }
  private updateRoutes() {
    console.log("updating routes...");
    var baseHref = this.faService.getFeatureAppSubPath();
    if (!baseHref.endsWith("/")) baseHref = baseHref + "/";
    if (baseHref.startsWith("/"))
      baseHref = baseHref.substring(1, baseHref.length);
    if (baseHref && baseHref != "/") {
      const updatedRoutes = rootRoutes.map((route) => ({
        ...route,
        path:
          route.path != ""
            ? baseHref + route.path
            : baseHref.substring(0, baseHref.length - 1),
      }));
      this.router.resetConfig(updatedRoutes);
    }
    this.router.initialNavigation();
    console.log("resolved initial navigation...");
  }
}
