import { Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormGroup, Validators } from "@angular/forms";
import { expandAnimation } from "src/app/animations/expand";
import { FormService } from "src/app/services/form.service";
import { PriceService } from "src/app/services/price.service";
import { TrackingService } from "src/app/services/tracking.service";
import {
  Client,
  PackageResponse,
  PackageTagEnum,
  PropertyEnum,
  PackageTypeEnum,
  AvailableTimeSlotsRequest,
  CalendarDayTimeSlotResponse,
  CalendarTimeSlotResponse,
  BusinessProposalResponse,
} from "src/domain/client";
import { PageBaseComponent } from "../../base/page-base/page-base.component";
import { PackageProductMapPipe } from "src/app/pipes/package-product-map.pipe";
import { debounceTime, filter } from "rxjs/operators";
import { BusinessProposalService } from "src/app/services/business-proposal.service";
import { CarBrandPipe } from "src/app/pipes/car-brand.pipe";
import { BrandEnum } from "src/app/classes/enums";
import * as moment from "moment";

@Component({
  selector: "app-time-selection",
  templateUrl: "./time-selection.component.html",
  animations: [expandAnimation],
})
export class TimeSelectionComponent
  extends PageBaseComponent
  implements OnInit, OnDestroy
{
  public PackageTagEnum = PackageTagEnum;
  public PropertyEnum = PropertyEnum;
  businessProposal$ = this.businessProposalService.businessProposal$;
  businessProposalServiceWindow: Date[] = [];

  constructor(
    public formService: FormService,
    public priceService: PriceService,
    private trackingService: TrackingService,
    private packageProductMapPipe: PackageProductMapPipe,
    private businessProposalService: BusinessProposalService,
    private carBrandPipe: CarBrandPipe
  ) {
    super();
    this.formService.timeSelectionForm.markAsUntouched();
    this.formService.timeSelectionForm.markAsPristine();
    this.formService.previousFormValue =
      this.formService.timeSelectionForm.value;
  }
  ngOnDestroy(): void {
    if (this.state === this.ComponentStateEnum.InitializationFailed) {
      this.formService.availableTimesResponse = null;
      this.formService.selectedWorkshopDate = null;
      this.formService.selectedWorkshopTime = null;
    }
    if (!this.formService.selectedDropoffOption) return;
    if (!this.date?.pristine || !this.dropoffOption?.pristine)
      this.trackingService.trackDateSelected(
        this.date.value,
        this.formService.selectedDropoffOption?.description?.header,
        this.formService.selectedDropoffOption.subPackages.map((x) => x.name)
      );
    this.cleanUp();
  }

  ngOnInit(): void {
    this.trackListView();
    if (!!this.formService.availableTimesResponse) {
      this.date.setValue(this.formService.selectedWorkshopDate?.date ?? null);
      this.evaluateForm();

      if (!!this.businessProposal$.value) {
        this.setServiceWindowData(this.businessProposal$.value);
      }

      this.state = this.ComponentStateEnum.Initialized;
      return;
    }
    var selectedPackages = this.formService.selectedBasePackages.concat(
      this.formService.selectedAdditionalPackages
    );
    this.state = this.ComponentStateEnum.Loading;
    new Client()
      .apiV2CalendarAvailableTimeSlots(
        AvailableTimeSlotsRequest.fromJS({
          dealerNumber: this.formService.selectedWorkshop.number,
          chassiNumber: this.formService.carInfoResponse.chassiNumber,
          odometer: this.formService.milageValue * 10,
          selectedPackages: selectedPackages,
        })
      )
      .then((response: CalendarDayTimeSlotResponse[]) => {
        this.formService.availableTimesResponse = response;

        this.formService.initSpecificFormFromPackages(
          this.formService.timeSelectionForm,
          this.formService.basePackagesResponse.filter(
            (p) => p.type == PackageTypeEnum.DropoffOption
          )
        );
        if (!!response.length) {
          this.state = this.ComponentStateEnum.Initialized;
          this.date.setValue(response[0].date);
          this.formService.selectedWorkshopDate = response[0];

          if (!!this.businessProposal$.value) {
            this.setServiceWindowData(this.businessProposal$.value);
          }
        }
        if (!response.length)
          this.state = this.ComponentStateEnum.InitializationFailed;
      })
      .catch((error) => {
        console.error(error);
        this.state = this.ComponentStateEnum.ApiCallFailed;
      });

    this.subscription = this.dropoffOption.valueChanges
      .pipe(
        debounceTime(100),
        filter((opt) => !!opt)
      )
      .subscribe(() => this.evaluateForm());
  }

  private setServiceWindowData(proposal: BusinessProposalResponse): void {
    if (!proposal.leadCreatedAt) return;

    const createdAt = new Date(proposal.leadCreatedAt);
    let fromDaysAddition = 0;
    let toDaysAddition = 0;
    const brandName = this.carBrandPipe.transform(proposal.vehicle);

    if (brandName === BrandEnum.audi) {
      // If Audi = to: 45 days from proposal creation date. from: 15 days before "to".
      toDaysAddition = 45;
      fromDaysAddition = toDaysAddition - 15;
    } else {
      // else = to: 32 days from proposal creation date. from: 17 days before "to"
      toDaysAddition = 32;
      fromDaysAddition = toDaysAddition - 17;
    }

    const serviceWindowFrom = moment(createdAt).add(fromDaysAddition, "days");
    const serviceWindowTo = moment(createdAt).add(toDaysAddition, "days");
    const daysDiff = serviceWindowTo.diff(serviceWindowFrom, "days");
    const rangeFromTo = Array.from({ length: daysDiff }).map((_, i) => {
      return moment(serviceWindowFrom).add(i, "days").toDate();
    });

    //  Set pre-selected date to from (if in future), else: "in two days"
    const inTwoDays = moment(new Date()).add(2, "days");
    const preSelectedDate = serviceWindowFrom.isAfter(inTwoDays)
      ? serviceWindowFrom
      : inTwoDays;

    this.formService.timeSelectionForm.patchValue({
      date: preSelectedDate.toDate(),
    });
    this.businessProposalServiceWindow = rangeFromTo;
  }

  onSelectDay(date: Date): void {
    let newOption = null;
    if (!!this.selectedDate && !!this.selectedDropoffOption)
      newOption = this.selectedDate.availableDropoffoptionIds?.includes(
        this.selectedDropoffOption.id
      )
        ? this.selectedDropoffOption
        : null;
    this.formService.timeSelectionForm.patchValue({
      date,
      dropoffOption: newOption,
      time: null,
      calendarPackage: null,
    });

    this.formService.selectedWorkshopDate = this.selectedDate;
    this.date.markAsDirty();
  }

  onTimeSelected(selectedTime: string): void {
    const hours = parseInt(selectedTime.split(":")[0], 10);
    const minutes = parseInt(selectedTime.split(":")[1], 10);
    const newDate = this.date.value as Date;
    newDate.setHours(hours, minutes);
    this.formService.timeSelectionForm.patchValue({
      date: newDate,
      time: selectedTime === this.time.value ? null : selectedTime,
    });

    this.formService.selectedWorkshopTime =
      this.formService.selectedWorkshopDate?.timeSlots?.find(
        (t: CalendarTimeSlotResponse) => t.slot === selectedTime
      );
  }

  timeSelectionParentForm(id: string): FormGroup<any> {
    return this.formService.timeSelectionForm.get(id) as FormGroup<any>;
  }

  get dropoffOptions(): PackageResponse[] {
    return !!this.formService.basePackagesResponse
      ? this.formService.basePackagesResponse.filter(
          (o: PackageResponse) =>
            !!this.selectedDate &&
            o.type == PackageTypeEnum.DropoffOption &&
            this.selectedDate.availableDropoffoptionIds.includes(o.id)
        )
      : [];
  }
  get workshopDates(): CalendarDayTimeSlotResponse[] {
    return !!this.formService.availableTimesResponse
      ? this.formService.availableTimesResponse
      : [];
  }
  get date(): AbstractControl {
    return this.formService.timeSelectionForm.get("date");
  }
  get time(): AbstractControl {
    return this.formService.timeSelectionForm.get("time");
  }
  get dropoffOption(): AbstractControl {
    return this.formService.timeSelectionForm.get("selectedContainer");
  }

  get selectedDate(): CalendarDayTimeSlotResponse {
    if (!this.date.value) return undefined;
    return this.workshopDates.find(
      (d: CalendarDayTimeSlotResponse) =>
        d.date.toDateString() === this.date.value.toDateString()
    );
  }

  get selectedDropoffOption(): PackageResponse {
    return (
      !!this.selectedDate &&
      this.formService.basePackagesResponse?.find(
        (d: PackageResponse) => d.id === this.dropoffOption.value
      )
    );
  }

  get selectedDayAvailableHours(): CalendarTimeSlotResponse[] {
    if (!this.selectedDate) return [];
    return this.selectedDate.timeSlots;
  }

  evaluateForm(): void {
    if (!this.selectedDate) return;
    if (!this.selectedDropoffOption) return;
    const dropoff = this.dropoffOptions.find(
      (o) => o.id === this.selectedDropoffOption.id
    );
    if (!dropoff.properties.includes(PropertyEnum.TimeSlot)) {
      this.time.clearValidators();
      this.time.updateValueAndValidity();
      return;
    }
    this.time.setValidators(Validators.required);
    this.time.updateValueAndValidity();
    this.formService.timeSelectionForm.updateValueAndValidity();
    return;
  }

  private trackListView(): void {
    this.trackingService.trackItemListViewed(
      "Step6",
      [].concat.apply(
        [],
        this.formService.basePackagesResponse
          .filter(
            (x) =>
              x.type == PackageTypeEnum.CheckInOption ||
              x.type == PackageTypeEnum.DropoffOption ||
              x.type == PackageTypeEnum.Mobility
          )
          .map((x) =>
            [
              this.packageProductMapPipe.transform(
                x,
                this.formService.carInfoResponse?.brandName
              ),
            ].concat(
              x.subPackages.map((sp) =>
                this.packageProductMapPipe.transform(
                  sp,
                  this.formService.carInfoResponse?.brandName
                )
              )
            )
          )
      )
    );
  }
}
