import { Injectable } from "@angular/core";
import { IFeatureAppConfig } from "../interfaces/interfaces";
import { BrandEnum } from "../classes/enums";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FeatureAppService {
  private config: IFeatureAppConfig;
  public isFeatureApp: boolean =
    typeof FEATURE_APP_BASE_URL !== "undefined" && !!FEATURE_APP_BASE_URL;
  public isInitialized = new Subject<any>();

  constructor() {
    console.log("assigning feature-app config to service");
    this.config = window["featureAppConfig"];
  }

  initialize(): void {
    console.log("assigning feature-app config to service");
    this.config = window["featureAppConfig"];
    this.isInitialized.next(true);
  }

  getFeatureAppBrand(): BrandEnum {
    return this.config?.brand;
  }

  getFeatureAppDealers(): string[] {
    if (!this.config?.dealers) return [];
    return this.config.dealers;
  }
  getFeatureAppSubPath(): string {
    if (!this.config) return "";
    if (this.config.subPath.endsWith("/")) return this.config.subPath ?? "";
    return this.config.subPath + "/";
  }

  getFeatureAppBaseUrl(): string {
    if (!this.config) return "/";
    return FEATURE_APP_BASE_URL;
  }

  shouldHideLogo(): boolean {
    if (!this.config) return false;
    return this.config?.hideLogo;
  }

  featureAppRegNr(): string {
    if (!this.config) return "";
    return this.config.regNr;
  }
}
