import { Component, Input, OnInit } from "@angular/core";
import { FormService } from "src/app/services/form.service";
import { PriceService } from "src/app/services/price.service";
import {
  PackageResponse,
  PackageTagEnum,
  PackageTypeEnum,
  PriceTypeEnum,
} from "src/domain/client";

@Component({
  selector: "app-package-summary",
  templateUrl: "./package-summary.component.html",
})
export class PackageSummaryComponent implements OnInit {
  @Input() package: PackageResponse;

  PackageTypeEnum = PackageTypeEnum;
  PackageTagEnum = PackageTagEnum;
  PriceTypeEnum = PriceTypeEnum;

  constructor(
    public formService: FormService,
    public priceService: PriceService,
  ) {}

  ngOnInit(): void {}

  // This should be changed during refactor step

  hasAllSelected(pkg: PackageResponse): boolean {
    if (pkg.type != PackageTypeEnum.OriginalService) return false;
    if (
      pkg.subPackages.filter((p) => !p.tags.includes(PackageTagEnum.Removable))
        .length >= pkg.subPackages.length
    )
      return true;
    return (
      !!pkg.subPackages.length &&
      this.formService.selectedBasePackages.find((j) => j.packageId === +pkg.id)
        .subPackageIds.length ===
        pkg.subPackages.length - 1
    );
  }
}
