import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  IViewPair,
  IWindscreenForm,
  IWindscreenWizardForm,
} from "src/app/interfaces/interfaces";
import { FormService } from "src/app/services/form.service";
import { PackageResponse, PropertyEnum } from "src/domain/client";

@Component({
  selector: "app-windscreen-wizard",
  templateUrl: "./windscreen-wizard.component.html",
})
export class WindscreenWizardComponent implements OnInit {
  public breakpoint = 4;
  public replacementPackage: PackageResponse = null;
  public repairPackage: PackageResponse = null;

  @Input() windscreenPackages: PackageResponse[];
  @Input() windscreenForm: FormGroup<IWindscreenForm>;
  @Input() insuranceViewPairs: IViewPair[];

  constructor(private formService: FormService) {}

  ngOnInit(): void {
    this.replacementPackage = this.windscreenPackages.find((j) =>
      j.properties.includes(PropertyEnum.WindscreenReplacement),
    );

    this.repairPackage = this.windscreenPackages.find((j) =>
      j.properties.includes(PropertyEnum.WindscreenRepair),
    );

    this.wizardForm?.controls.issue?.valueChanges.subscribe({
      next: (_) => {
        if (!!this.suggestedReplacement) {
          this.windscreenForm?.controls?.selectedContainer.patchValue(
            this.replacementPackage.id,
          );
        }
      },
    });

    this.wizardForm.controls?.issue?.valueChanges.subscribe({
      next: (_) => {
        if (
          !!this.suggestedRepair &&
          !!this.damages &&
          this.damages < this.breakpoint
        ) {
          this.windscreenForm?.controls.selectedContainer?.patchValue(
            this.repairPackage.id,
          );
        }
      },
    });
  }

  packageFormGroup(id: string): FormGroup<any> {
    return this.windscreenForm.get(id) as FormGroup<any>;
  }
  resetForm(): void {
    this.wizardForm.reset();
  }

  get suggestedReplacement(): boolean {
    return this.wizardForm?.controls.issue?.value === "replace";
  }

  get suggestedRepair(): boolean {
    return this.wizardForm?.controls.issue?.value === "repair";
  }

  get damages(): number {
    return parseInt(this.wizardForm.controls?.damages?.value, 10);
  }

  get wizardForm(): FormGroup<IWindscreenWizardForm> {
    return this.formService.windscreenWizardForm;
  }
}
