import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-secondary-confirmation-box",
  templateUrl: "./secondary-confirmation-box.component.html",
})
export class SecondaryConfirmationBoxComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() checkboxText: string;
  @Input() parentForm: FormGroup<any>;
  @Input() formControlKey: string;
  @Input() link?: string;
  @Input() linkText?: string;

  constructor() {}

  ngOnInit(): void {}
}
