// These classes need recompilation to work in Angular 16+
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";

import {
  NGX_HOTJAR_INITIALIZER_PROVIDER,
  NGX_HOTJAR_ROUTER_INITIALIZER_PROVIDER,
} from "ngx-hotjar";
import { NGX_HOTJAR_SETTINGS_TOKEN } from "ngx-hotjar";

/**
 * Install Hotjar scripts on Angular Startup life cycle if this environment is a Browser, otherwise just ignore this step.
 *
 * You shall add this module on the Gighest level module of your application, aka `AppModule`. When
 * setup this module, you also have to provide you hotjat tracking code and the version of script. The default version
 * is 6. We do not recoment to expose the tracking code in the repository, so please, use angular environment variable.
 *
 * ## Exemple of Use
 *
```typescript
\@NgModule({
  ...
  imports: [
    ...
    NgxHotjarModule.forRoot(envorinment.hj)
// ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  ]
})
```
 */
@NgModule({
  imports: [],
  declarations: [],
  exports: [],
})
export class NgxHotjarModule {
  /**
   * Setup global settings and provide Hotjar Services.
   *
   * You private tracking code. This tracking code is also known as the same number as `SITE ID` inside Hotjar Dashboard.
   */
  static forRoot(
    trackingCode: string,
    version: number = 6,
    uri?: string,
  ): ModuleWithProviders<NgxHotjarModule> {
    return {
      ngModule: NgxHotjarModule,
      providers: [
        {
          provide: NGX_HOTJAR_SETTINGS_TOKEN,
          useValue: {
            trackingCode,
            version,
            uri,
          },
        },
        NGX_HOTJAR_INITIALIZER_PROVIDER,
      ],
    };
  }
}

/**
 * Attach a listener to `NavigationEnd` Router event. So, every time Router finish the page resolution it should call `NavigationEnd` event.
 * We assume that NavigationEnd is the final page resolution and call Hotjar `stateChange` command.
 *
 * To avoid double binds, we also destroy the subscription when de Bootstrap Component is destroied. But, we don't know for sure
 * that this strategy does not cause double bind on multiple bootstrap components.
 *
 * We are using de component's injector reference to resolve Router, sou I hope there is no problem w/ double bing.
 *
 * If you have this problem, I encourage not Use NgxHotjarRouterModule and atach the listener on AppComponent initialization.
 *
 * This Module is just a sugar for:
 *
```typescript
constructor(private router: Router) {}
...
ngOnInit() {
  ...
  this.router
    .events
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(event => {
      if (event instanceof NavigationEnd) {
        hjService.pageView(event.urlAfterRedirects, undefined);
      }
    });
```
 */
@NgModule({
  imports: [CommonModule, NgxHotjarModule],
  providers: [NGX_HOTJAR_ROUTER_INITIALIZER_PROVIDER],
  declarations: [],
})
export class NgxHotjarRouterModule {}
